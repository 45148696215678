.pricing-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25%;
  font-size: 90%;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.cards .pricing-wrapper {
  width: calc(100% - 20px);
  padding: 10px;
}

.pricing-pricing-travler-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
}

.pricing-pricing-travler-title {
  font-weight: 500;
}

.pricing-pricing-agent-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.pricing-pricing-agent-title {
  font-size: 85%;
  font-weight: 500;
}

.pricing-pricing-travler-price {
}

.pricing-pricing-agent-price {
  font-weight: 600;
}

.pricing-buttons-wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.cards .pricing-buttons-wrapper {
  width: unset;
  padding: 10px;
}

.pricing-button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  padding: 5%;
  margin: auto;
  font-size: 100%;
  height: 40px;
}

.pricing-button-blue {
  background-color: #0258a4;
}

.pricing-button-blue:hover {
  background-color: white;
  color: #0258a4;
  border: 2.5px solid #0258a4;
  transition: all ease 500ms;
}

.pricing-button-orange {
  background-color: #ff3703;
}

.pricing-button-orange:hover {
  background-color: white;
  color: #ff3703;
  border: 2.5px solid #ff3703;
  transition: all ease 500ms;
}

.pricing-pricing-travler-price-taxes {
  font-size: 80%;
  font-weight: 500;
  color: black;
}
