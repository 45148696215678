.checkInOutDisplayer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4% 3.5px;
  align-items: center;
}

.checkInOutDisplayer-title-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.checkInOutDisplayer-title {
  font-weight: 700;
  color: black;
  font-size: 100%;
  width: max-content;
}

.checkInOutDisplayer-title-time {
  color: #25ac03;
}

.checkInOutDisplayer-title-icon {
  margin: auto;
}

.checkInOutDisplayer-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 75%;
  justify-content: center;
  align-items: center;
}

.checkInOutDisplayer-slider-wrapper {
  width: 100%;
}

.checkInOutDisplayer-sub-title {
  font-size: 50%;
  font-weight: 500;
  width: max-content;
}

.checkInOutDisplayer-title-no-data {
  font-size: 75% !important;
  font-weight: 500 !important;
}
