.roomsFilter-wrapper {

}

.roomsFilter-select-wrapper {
  padding: 1%;
  border-color: #ff370366;
  cursor: pointer;
  border-radius: 5px;
  min-width: 25%;
}

.roomsFilter-select-wrapper:hover {
  outline: none;
  border-color: #ff623966;
  transition: all 500ms ease;
}

.roomsFilter-filtrations {
  display: flex;
  flex-direction: row;
  gap: 5px;
  background: #f3f3f3;
  padding: 2%;
  width: 100%;
}

.roomsFilter-reset-button-wrapper {
  display: flex;
  justify-content: end;
  margin: 0 2%;
  width: fit-content;
}

.roomsFilter-reset-button {
  border-radius: 3px;
  color: white;
  background-color: #ff5427;
  border: none;
  padding: 1.1vh 1.7vw;
  white-space: nowrap;
  cursor: pointer;
}

.roomsFilter-reset-button:hover {
  color: #ff5427;
  background-color: white;
  border: 1px solid #ff5427;
  transition: all 500ms ease;
}
