.roomDisplayer-object-room-img {
  width: 100%;
  direction: ltr;
}

.roomDisplayer-object-room-img-zoom-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 100;
  background: #747474ad;
  justify-content: center;
  display: flex;
  align-items: center;
}

.roomDisplayer-object-room-img-zoom {
  padding: 5%;
  width: 55vw;
  height: fit-content;
}

.roomDisplayer-room-image-wrapper {
  position: relative;
}

.roomPlacer-image-zoom {
  height: 70vh;
}

.roomPlacer-image {
  border-radius: 2px;
  height: 400px;
  width: 300px;
  object-fit: cover;
}

.roomDisplayer-room-image {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: black;
  font-weight: 600;
  font-size: 75%;
  background: white;
  border-radius: 7px;
  padding: 2px 3%;
  cursor: pointer;
}

.roomDisplayer-room-image:hover {
  background: #f5f5f5;
}

@media (max-width: 768px) {
  .roomPlacer-image {
    height: 200px;
  }
}
