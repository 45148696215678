.stages-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stage-line {
  height: 2px;
  width: 40px;
  background-color: rgba(128, 128, 128, 0.55);
  margin: 0 5px;
}
.stage-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3px;

  &:hover {
    cursor: pointer;
  }
  &.disabled:hover {
    cursor: default !important;
  }
}
.stage-container-unactive {
  filter: opacity(0.4);
}
.missing-fields {
  color: red;
  text-shadow: 0 0 5px red;
  filter: opacity(1);
}

.stage-icon {
  margin: 0 2px 0 5px;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background: radial-gradient(
    circle,
    rgb(172, 170, 170) 0%,
    rgb(172, 170, 170) 45%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 2px solid rgb(172, 170, 170);
  box-shadow: 0 0 3px rgb(172, 170, 170);
}
.stage-icon-active {
  background: radial-gradient(
    circle,
    rgb(172, 170, 170) 0%,
    rgb(172, 170, 170) 38%,
    rgba(255, 255, 255, 1) 38%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 2px solid rgb(255, 130, 0);
  box-shadow: 0 0 3px rgb(255, 130, 0);
}

.stage-title {
  display: inline-block;
  text-align: center;
}

@media (max-width: 1024px) {
  .stages-wrapper {
    font-size: 2vw;
    font-size: 12px;
    width: 100%;
  }

  .stage-line {
    width: 2vw;
    max-width: 20px;
  }
}
