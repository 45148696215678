.finance-header-wrapper {
    margin: 3vh 0;
    padding: 0 50px;
    height: 10vh;
    background-color: white;
    text-align: right;
    color: #4081BA;
    opacity: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.finance-logo{
width: 50px;
height: 50px;
}

.main-header{
 font-weight: bold;
 font-size: 25px;
    margin-right: 10px;
}
  