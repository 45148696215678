.passangerData-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  margin-left: 1em;
  font-size: 115%;
}

.passangerData-room-pass-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.passangerData-special-requests-wrapper {
  background: white;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 5px;
}

.passangerData-special-requests-title {
  font-size: 80%;
  font-weight: 500;
  color: #ff3703;
}

.passangerData-special-requests-textarea {
  outline: none;
  border-color: #e2e2e2;
  border-radius: 1px;
  font-family: "Gill Sans", sans-serif;
  resize: none;
}

.passangerData-special-requests-textarea::placeholder {
  font-size: 80%;
}

.passangerData-cancelation-policy-wrapper {
  background-color: white;
  border-radius: 10px;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.passangerData-cancelation-policy-title {
  font-size: 80%;
  font-weight: 500;
  color: #ff3703;
}

.passangerData-cancelation-policy-sub-title {
  font-size: 65%;
  font-weight: 500;
  color: grey;
}

.passangerData-cancelation-policy-cancel-wrapper {
  background-color: #f1f1f1;
  padding: 1% 5%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passangerData-cancelation-policy-cancel-title {
  font-size: 90%;
  color: #3cba25;
  font-weight: 600;
}

.passangerData-cancelation-policy-cancel-title-not-free {
  font-size: 90%;
  color: red;
  font-weight: 600;
}

.passangerData-cancelation-policy-cancel-more {
  font-size: 65%;
  cursor: pointer;
  width: max-content;
}

.passangerData-cancelation-policy-cancel-more:hover {
  text-decoration: underline;
}

.passangerData-checkout-wrapper {
  padding: 1% 5% 5%;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}

.passangerData-checkout-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 3px;
}

.passangerData-checkout-checkbox {
  border-color: #ff3703;
  cursor: pointer;
  margin: auto;
}

.passangerData-checkout-checkbox-text {
  font-size: 80%;
  font-weight: 500;
  cursor: pointer;
  color: #4081ba;
}

.passangerData-checkout-checkbox-text-black {
  font-size: 80%;
  font-weight: 500;
}

.button-disabled {
  border: none;
  padding: 7.5px 15px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
}

.passangerData-checkout-checkbox-text:hover {
  text-decoration: underline;
  transition: all 500ms ease;
}

.passangerData-checkout-button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  align-items: center;
  gap: 5%;
  margin: 10% 0;
}

.passangerData-checkout-button {
  background: #ff3703;
  color: white;
  border: none;
  padding: 7.5px 15px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
}

.passangerData-checkout-button:hover {
  background: white;
  color: #ff3703;
  border: 1px solid #ff3703;
  transition: all 500ms ease;
}

.passangerData-showmore-wrapper {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  align-items: center;
}

.assangerData-showmore-arrow {
  font-weight: 500;
  cursor: pointer;
}

.passangerData-cancelation-policy-wrapper-wide {
  max-height: 24vh;
  transition: all 500ms ease;
}

.passangerData-cancelation-policy-remarks {
  height: 15vh;
  overflow-y: auto;
  font-size: 70%;
}

.passangerData-cancelation-policy-cancel-heading-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.passangerData-cancelation-entrys {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passangerData-cancelation-entry {
  font-size: 85%;
  font-weight: 500;
}

.passangerData-cancelation-empty {
  width: 100%;
  padding: 15px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 90%;
}

.passangerData-submit-button-pay-later {
  background: #0258b6;
  color: white;
  border: none;
  padding: 7.5px 15px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
}

.passangerData-submit-button-pay-later:hover {
  background: white;
  color: #0258b6;
  border: 1px solid #0258b6;
  transition: all 500ms ease;
}

.passangerData-submit-button-wrapper {
  width: fit-content;
}

.passangerData-cancelation-entrys-button-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.passangerData-cancelation-entrys-remark-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5%;
  justify-content: center;
}

.passangerData-popup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #878787c9;
  z-index: 1000000000;
}

.passangerData-popup-frame {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #9e9b9b;
  position: relative;
  width: 65%;
  overflow-y: auto;
  height: 75%;
}

.passangerData-popup-frame-content {
  padding: 5%;
  text-align: right;
}

.passangerData-popup-agree-btn-wrapper {
  display: flex;
  justify-content: center;
  bottom: 0;
  /* position: absolute; */
  width: 100%;
  padding: 4% 0;
}

.passangerData-popup-agree-btn {
  border-radius: 3px;
  color: white;
  background-color: #ff5427;
  border: none;
  padding: 1.5% 4%;
  cursor: pointer;
}

.passangerData-popup-agree-btn:hover {
  color: #ff5427;
  background-color: white;
  border: 1px solid #ff5427;
  transition: all 500ms ease;
}

.modal-cancelation-policy-remarks {
  height: 100%;
  overflow-y: auto !important;
  direction: ltr;
}
.modal-cancelation-entrys {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #ff5427;
  font-weight: 600;
  font-size: larger;
  padding-top: 15px;
}
