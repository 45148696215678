.filters-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .filters-tabs {
    margin-top: 20px;
  }
}
