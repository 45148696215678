@import "../../../../assets/variables";

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: unset;
  margin-right: 10px;
}

.ant-tabs-nav-wrap {
  padding-left: 1rem;
  padding-right: 1rem;
}

.cards .hotel-data {
  display: flex;
  flex-direction: column;
}

.package-wrapper {
  background-color: white;
  padding: 1%;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #b5b3b3;

  .hotel-data {
    margin-bottom: 10px;
    .hotel-img {
    }
    .center-hotel-data {
      padding: 0 3%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex: 1;

      .hotel-city {
        color: $blue;
        font-weight: 700;
      }

      .hotel-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        gap: 10px;
        font-size: large;
        font-weight: 600;
      }

      .hotel-category {
      }

      .text-with-icon {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        gap: 10px;

        .orange-icon {
          color: $orange;
        }

        .text {
        }
      }
    }
    .price-data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 3%;

      .total-price {
        font-size: xx-large;
        color: $blue;
        font-weight: bold;
      }

      .total-price-usd {
        font-size: medium;
        color: $blue;
        font-weight: bold;
      }

      .totalFare {
        margin-top: 10px;
        font-weight: 600;
        font-size: medium;
      }
      .order-btn {
        padding: 10px;
        width: 50%;
      }

      .auto-approve {
        color: $green;
        font-weight: 700;
      }
      .not-auto-approve {
        color: red;
        font-weight: 700;
      }
    }
  }
}
