.bottomNav-bar {
  background: #0258a417;
  width: 100%;
  /* height: fit-content; */
  height: 150px;
  position: relative;
  margin-top: 20px;
  flex-shrink: 0;
}

.bottomNav-bar-bottom {
  background: #0258a4;
  width: 100%;
  /* height: fit-content; */
  height: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
}
