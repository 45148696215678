.finance-table {
  height: 40vh;
  overflow: auto;
}

.finance-table > .open-table-mobile-header {
  display: none;
}

.no-data {
  font-size: larger;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  display: flex;
  // width: 70rem;
  height: 100%;
  font-size: 1.5rem;
}

.table-header-row {
  text-align: center !important;
  border: 1px white;
  background-color: #0258a4;
  margin-left: 50px;
}

.table-header-cell {
  text-align: center !important;
  border-right: 2px solid white;
  border-left: 2px solid white;
  color: white !important;
  font-weight: 700 !important;
}

.table-row-paid {
  background-color: #effaee;
}

.table-row-cell {
  text-align: center !important;
}

.collapsed-wrapper {
  min-height: 100px;
  // width: 100%;
  display: flex;
  flex-direction: rtl;
  font-size: medium;
}

.column-wrapper {
  display: flex;
  flex-direction: column;
  text-align: right !important;
  margin: 5px 50px;
  width: 25%;
}

.item-wrapper {
  margin: 10px;
  font-size: 1rem;
}

.item-header {
  font-weight: 800;
  font-size: 1.1rem;
}

.cancel-until-cell {
  font-weight: 800;
  color: #25ac02;
}

.commisions-cell {
  font-weight: 800;
  color: rgb(254 55 2);
}

.table-button {
  width: 150px;
  height: 30px;
  font-weight: 800;
  border: none;
  border-radius: 6px;
}

.table-button-cancel-now {
  color: white;
  width: 150px;
  height: 30px;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  background-color: rgb(254 55 2);
}

.table-button-pay-now {
  color: white;
  width: 150px;
  height: 30px;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  background-color: #25ac02;
}

.table-button-disabled {
  width: 150px;
  height: 30px;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  pointer-events: none;
}

.table-button-active {
  width: 150px;
  height: 30px;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  background-color: #0258a4;
  color: white;
}

.policies-cell {
  font-size: x-small;
  height: 400px;
}

.box-style {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border: "2px solid #000";
  box-shadow: 24;
  background-color: white;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  padding: 15px;
}

.modal-cancel-order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: "2px solid #000";
  box-shadow: 24;
  background-color: white;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  padding: 15px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-order-content {
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.info-icon {
  display: inline;
  padding: 5px;
}

.sum-positive {
  color: #25ac02;
}

.sum-negative {
  color: #ff3703;
}

.status-aprroved {
  font-weight: bold;
  color: #25ac02;
}
.status-waiting {
  font-weight: bold;
  color: #0091f2;
}
.status-applied {
  font-weight: bold;
  color: #ff3703;
}

.table-actions-cell {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.approve-requset {
  color: white;
  background-color: #25ac02;
  border: none;
  padding: 3% 10%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
}

.decline-requset {
  color: white;
  background-color: #ff3703;
  border: none;
  padding: 3% 10%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
}

@media (max-width: 1024px) {
  .finance-table {
    height: unset;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    // overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  .finance-table > .open-table-mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    color: #0258a4;
    cursor: pointer;
  }

  .finance-table > .open-table-mobile-header > .open-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .finance-table > .open-table-mobile-header > .open-btn > img {
    width: 20px;
    aspect-ratio: 1;
  }

  .finance-table.closed > .open-table-mobile-header > .open-btn > img {
    transform: unset;
  }

  .finance-table > .open-table-mobile-header > .open-btn > img {
    transform: rotate(180deg);
  }

  .finance-table.closed > .table-wrapper {
    display: none;
  }

  .column-wrapper {
    width: 50%;
    margin: unset;
    margin-top: 10px;
  }
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    overflow-x: visible !important;
    width: max-content !important;
  }
}
