.sideNav-wrapper {
  margin-top: 50px;
}

.side-nav-icon {
  width: 25px;
  height: 25px;
  color: white;
}

.side-nav-image {
  width: 25px;
  height: 25px;
}

.side-nav-logout {
  color: #25ac03;
  font-weight: 700;
  font-size: 100%;
  letter-spacing: -0.5px;
  margin-top: 27.5%;
  cursor: pointer;
}

.user-details {
  font-size: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  width: 100%;
  gap: 10px;
}

.side-nav-login-user {
  background-color: #25ac03;
  color: white;
  cursor: pointer;
  height: 8% !important;
  box-shadow: 0px 2px 4px #9e9b9b;
}

.side-nav-login-user:hover {
  filter: brightness(0.9);
}
.side-nav-login-user:active {
  filter: brightness(0.8);
}

.side-nav-image {
  background: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.side-nav-login-user-item {
  font-weight: 600;
  text-align: right;
}

.arrow-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-wrapper-open {
  width: 20px;
}
.arrow-wrapper-closed {
  width: 100%;
}

@media (max-width: 1024px) {
  .SideNav-wrapper {
    display: none;
  }
}
