.string-input-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.string-input-title {
}

.string-input-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.string-input {
  border: transparent;
  width: 100%;
  outline: none;
  background-color: transparent;
}

@media (max-width: 1024px) {
  .string-input-wrapper {
    width: 100%;
  }
}
