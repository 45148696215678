@import "../../assets/variables";
.select-input-container {
  display: flex;
  align-items: flex-end;
  .title {
    color: black;
  }
  .input {
    box-shadow: 0 2px 5px 0 #00000047 inset;
    border: 1px solid #b5b3b3;
    border-radius: 1000px;
    padding: 5px 12px;
    width: 100%;
  }
}
