.withdrawal-widget {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 70%;
  box-shadow: 2px 3px 6px #0000001c;
  border-radius: 10px;
  padding: 0 3vh;
}

.withdrawal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 2%;
  height: 5vh;
}

.withdrawal-body {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 1% 0;
  align-items: center;
  display: flex;
  height: 35%;
  height: 10vh;
}

.withdrawal-logo {
  width: 40px;
  height: 40px;
  padding: 10px;
}

.send-withrawal-btn {
  background-color: #0258a4;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 2vh;
  border: none;
  height: 30px;
  width: 150px;
  margin-top: 18px;
}

.send-withrawal-loader {
  padding: 0px 2vh;
  height: 30px;
  width: 150px;
  margin-top: 18px;
}

.withdrawal-footer {
  padding-top: 1%;
  height: 5vh;
}

.email-select {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .withdrawal-widget {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .withdrawal-body {
    flex-direction: column;
    gap: 10px;
    height: unset;
  }

  .email-select {
    width: 100%;
  }
}
