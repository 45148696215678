.orderSummery-wrapper-wrapper {
  height: 100%;
}

.orderSummery-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.orderSummery-zoom-in-wrapper-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  background: #878787b3;
  width: 100%;
  display: flex;
}

.orderSummery-zoom-in-wrapper {
  width: 35%;
  background-color: white;
  margin: auto;
  height: 85%;
  border-radius: 7.5px;
  z-index: 100;
}

.orderSummery-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 0;
  width: 100%;
  margin: auto;
}

.orderSummery-header-item {
  width: max-content;
  margin: auto;
  cursor: pointer;
  color: black;
  font-weight: 500;
  text-align: center;
  padding-bottom: 2%;
  letter-spacing: -0.5px;
  white-space: nowrap;
  font-size: 90%;
}

.orderSummery-header-item-selected {
  font-weight: 700;
  font-size: 92.5%;
  color: #0258a4;
  border-bottom: 2px solid #0258a4;
  transition: all ease 100ms;
}

.orderSummery-content {
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.orderSummery-details {
  display: flex;
  flex-direction: row;
  background-color: #25ac03;
  color: white;
  padding: 1% 5%;
  justify-content: space-between;
}

.orderSummery-details-item {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  gap: 1vh;
}

.orderSummery-details-agent {
  /* position: absolute;
    right: 3%; */
}

.orderSummery-details-agent-title {
  font-size: 95%;
}

.orderSummery-details-agent-name {
  font-size: 90%;
  font-weight: 400;
  margin: auto;
  width: 100%;
}

.orderSummery-content-item-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 5px;
  height: 75%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.orderSummery-wrapper-icon-he {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 2% 3% 3%;
}

.orderSummery-wrapper-icon {
  position: absolute;
  bottom: 2%;
  right: 2%;
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.orderSummery-icon {
  width: 25px;
  cursor: pointer;
}


.orderSummery-price-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding: 5% 3%;
  font-weight: 700;
  color: #0258a4;
  margin-top: 5%;
  width: 100%;
  gap: 10px;
}

.orderSummery-payment-btn-wrapper {
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
}

.orderSummery-payment-btn {
  cursor: pointer;
  border: none;
  border-radius: 20px;
  color: white;
  font-weight: 400;
  padding: 2% 6%;
  margin: auto;
  background-color: #ff3703;
  width: max-content;
  font-size: 90%;
}

.orderSummery-payment-btn:hover {
  color: #ff3703;
  border: 1px solid #ff3703;
  background-color: white;
  font-weight: 600;
  transition: all ease 500ms;
}

.orderSummery-price-payment-wrapper {
  background-color: #ff3703;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-weight: 700;
  font-size: 90%;
  padding: 2% 7%;
}

.orderSummery-narrow-button {
  font-size: 175%;
  cursor: pointer;
}

.orderSummery-narrow-button:hover {
  opacity: 0.7;
}

.orderSummery-content-infos {
  flex: 1;
}
