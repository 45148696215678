.hotelProfile-component {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.hotelProfile-wrapper {
  height: fit-content;
}

.hotelProfile-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hotelProfile-header {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  /* padding-top: 2%; */
  direction: rtl;
}

.hotelProfile-header-desc {
  display: flex;
  flex-direction: column;
  direction: ltr;
}

.hotelProfile-header-pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.hotelProfile-header-desc-title {
  color: #0258a4;
  font-weight: 700;
  font-size: 130%;
}

.hotelProfile-header-desc-sub-title {
  color: #656464;
  font-size: 90%;
}

.hotelProfile-header-pricing-btn {
  background-color: #ff3703;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 7.5% 20%;
  width: max-content;
}

.hotelProfile-header-pricing-btn:hover {
  background-color: white;
  border: 1px solid #ff3703;
  color: #ff3703;
  transition: all 500ms ease;
}

.hotelProfile-header-pricing-price {
  color: #0258a4;
  font-size: 110%;
  font-weight: 700;
}

.hotelProfile-header-pricing-title {
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 105%;
  justify-content: end;
}

.hotelProfile-header-pricing-sub-title {
  margin-top: auto;
  width: max-content;
  white-space: nowrap;
}

.hotelProfile-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hotelProfile-content-gallery-wrapper {
}

.hotelProfile-content-amenatis-googlemaps-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  gap: 3%;
}

.hotelProfile-content-amenatis-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 15%; */
  gap: 0.75rem;
}

.hotelProfile-content-all-amenatis-check-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}

.hotelProfile-content-all-amenatis-check {
  color: green;
  font-weight: 500;
}

.hotelProfile-content-googlemaps-wrapper {
  width: 40%;
  height: 17.5vh;
}

.hotelProfile-content-googlemaps {
  width: 100%;
  height: 100%;
}

.hotelProfile-content-googlemaps button {
  width: 25px !important;
  height: 25px !important;
}

.hotelProfile-content-search-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 2%;
  border-radius: 5px;
}

.hotelProfile-content-search-info-title {
  margin: auto 0;
}

.hotelProfile-content-search-info-title-date {
  font-weight: 600;
}

.hotelProfile-content-search-info-btn {
  background-color: #3a7eb5;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 1% 2%;
}

.hotelProfile-content-search-info-btn:hover {
  background-color: #fff;
  color: #3a7eb5;
  border: 1px solid #3a7eb5;
  transition: all 500ms ease;
}

.hotelProfile-describtion {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10%;
  gap: 1vw;
}

.hotelProfile-loading-screen-wrapper {
  width: 100%;
  height: 100%;
}

.hotelProfile-component .carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.hotelProfile-describtion-content {
  width: 65%;
}

.hotelProfile-describtion-maps {
  width: 40%;
}

.hotelProfile-describtion-img-wrapper {
  position: relative;
}

.hotelProfile-describtion-img {
  height: 60%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: cover;
}

.hotelProfile-describtion-img-title-wrapper {
  position: absolute;
  z-index: 10;
  left: 0%;
  bottom: 10%;
  background-color: #ffffffc4;
  width: 100%;
  padding: 1% 0;
}

.hotelProfile-describtion-img-title {
  font-weight: 700;
  padding: 0 5%;
}

.hotelProfile-describtion-about-title-wrapper {
  display: flex;
  justify-content: end;
}

.hotelProfile-describtion-about-impdata-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.hotelProfile-describtion-about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 7% 5%;
}

.hotelProfile-describtion-important-data-title-wrapper {
  display: flex;
  justify-content: end;
}

.hotelProfile-describtion-about-title {
  font-weight: 700;
  font-size: 95%;
  color: #25ac03;
}

.hotelProfile-describtion-about-content {
  text-align: start;
  height: 20vh;
  overflow-y: auto;
  direction: ltr;
  font-size: 95%;
  font-weight: 400;
}

.hotelProfile-describtion-important-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 7% 5%;
}

.hotelProfile-describtion-important-data-title {
  font-weight: 700;
  font-size: 95%;
  color: #25ac03;
}

.hotelProfile-describtion-important-data-content {
  text-align: start;
  height: 20vh;
  overflow-y: auto;
  direction: ltr;
  font-size: 95%;
  font-weight: 400;
}

.hotelProfile-describtion ::-webkit-scrollbar-thumb {
  background-color: #0258a4;
  border-radius: 20px;
  border: 0px solid transparent;
  background-clip: content-box;
}

.hotelProfile-describtion ::-webkit-scrollbar-track {
  background-color: #dedede;
}

.hotelProfile-describtion ::-webkit-scrollbar {
  height: 1rem;
  width: 0.4rem;
}

.hotelProfile-content-googlemaps-desc {
  width: 100%;
  height: 22.5vh;
}

.hotelProfile-describtion-about-content-wrapper {
  list-style: none;
  padding: 0;
}

.hotelProfile-describtion-about-content-row {
  position: relative;
  padding-left: 10px;
  /* Adjust as needed for spacing */
  margin-bottom: 10px;
  /* Adjust as needed for spacing between items */
  text-align: left;
  font-size: 85%;
}

.hotelProfile-describtion-about-content-row::before {
  content: "\2022";
  /* Unicode character for a bullet point (•) */
  color: #808080;
  /* Color of the bullet point */
  font-size: 13px;
  /* Adjust the size of the bullet point */
  position: absolute;
  left: 0;
  top: 10px;
  transform: translateY(-50%);
}

.hotelProfile-describtion-attractions {
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 5px 5px;
  padding: 7% 5%;
}

#map-2 {
  border-radius: 2px;
}

.hotelProfile-describtion-attractions-content-wrapper {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  height: 45vh;
  padding: 0 5px;
  direction: ltr;
}

.hotelProfile-describtion-attractions-content-row {
  position: relative;
  margin-bottom: 2px;
  text-align: left;
  font-size: 75%;
  direction: ltr;
  display: flex;
  gap: 5px;
}

.hotelProfile-describtion-attractions-title {
  font-weight: 700;
  font-size: 95%;
  color: #25ac03;
}

.hotelProfile-describtion-attractions-sub-title {
  font-weight: 700;
  font-size: 75%;
  color: #0258a4;
}

.hotelProfile-showArround {
  background-color: #0258a4;
  color: white;
  border: none;
  border-radius: 4px 4px 5px 5px;
  width: 100%;
  padding: 2% 0%;
  cursor: pointer;
}

.hotelProfile-showArround:hover {
  background-color: #0b68ba;
  transition: all 500ms ease;
}

.hotelProfile-checkInOutDisplayer-wrapper {
  background-color: #f6f6f6;
  margin-bottom: 10px;
}

.hotelProfile-checkInOutDisplayer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.hotelProfile-content-all-amenatis {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 75%;
  /* color: #8f8c8c; */
  color: black;
  justify-content: space-evenly;
  height: 12.5vh;
  overflow-y: auto;
}
