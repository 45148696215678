.phone-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.phone-line img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
