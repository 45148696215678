.package-table {
  //margin: 20px;
  margin: 0 5px;
  width: 220px;
  padding: 2px;
  box-shadow: 0 0 7px 1px #00000052;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.example {
    box-shadow: none;
  }
  &.disabled {
    filter: grayscale(3);
  }

  .package-table-title {
    height: 20px;
    padding: 10px 0;
    color: white;
    text-align: center;
    width: 100%;
  }
  .package-table-item {
    text-align: center;
    padding: 4px 30px;
    width: calc(100% - 60px);
    &.example {
      clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 12% 100%, 0% 50%, 12% 0);
    }
  }
}

.agent_packages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

.agent_packages > .packages > .package {
  display: flex;
  flex-direction: row;
}

.agent_packages > .packages > .package:nth-child(n + 2) > div:nth-child(1) {
  display: none;
}

@media (max-width: 1024px) {
  .agent_packages > .packages {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .agent_packages > .packages > .package {
    width: 100%;
  }

  .agent_packages > .packages > .package:nth-child(n + 2) > div:nth-child(1) {
    display: block;
  }

  .agent_packages > .packages > .package > div {
    width: 50%;
  }

  .agent_packages > .packages > .package > div .button {
    width: unset;
  }
}
