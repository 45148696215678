.clientsInfo-wrapper-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding-top: 100px;
}

.clientsInfo-wrapper {
  /* width: 25%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  padding: 5% 6.5%;
  box-shadow: 0px 2px 4px #cbc8c8; */
}

.clientsInfo-title {
  text-align: center;
  font-size: 145%;
  font-weight: 500;
  margin: 0;
  margin-bottom: 7.5%;
}

.clientsInfo-form-wrapper {
  display: flex;
  margin-bottom: 7.5%;
  flex-direction: column;
}

.clientsInfo-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  margin-bottom: 5%;
}

.clientsInfo-input-label {
  color: #2f76b4;
}

.clientsInfo-input {
  font-size: 95%;
  border: none;
  border-bottom: 1px solid #707070;
  color: black;
  padding: 2% 0%;
}

.clientsInfo-input:focus {
  border-color: #2f76b4;
  border-width: 1.2px;
  outline: none;
  font-size: 110%;
  color: black;
  transition: all ease 200ms;
}

.clientsInfo-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: -3%;
}

.clientsInfo-checkbox {
  cursor: pointer;
  border-color: #2f76b4;
}

.clientsInfo-checkbox-label {
  color: #2f76b4;
  font-size: 90%;
}

.clientsInfo-php-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 14.5%;
  width: 100%;
  justify-content: space-evenly;
}

.clientsInfo-php-item {
  text-align: center;
  border-radius: 3px;
  padding: 2% 4%;
  cursor: pointer;
  width: 22%;
  position: relative;
  z-index: 1;
}

.clientsInfo-php-orange {
  color: #ff3703;
  border: 1px solid #ff3703;
}

.clientsInfo-php-green {
  color: #25ac03;
  border: 1px solid #25ac03;
}

.clientsInfo-php-blue {
  color: #0258a4;
  border: 1px solid #0258a4;
}

.clientsInfo-plains {
  color: white;
  background-color: #ff3703;
  transition: all ease 500ms;
}

.clientsInfo-hotels {
  color: white;
  background-color: #25ac03;
  transition: all ease 500ms;
}

.clientsInfo-packages {
  color: white;
  background-color: #0258a4;
  transition: all ease 500ms;
}

.clientsInfo-php-item-label {
  cursor: pointer;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.clientsInfo-btn-wrapper {
  text-align: center;
}

.clientsInfo-btn {
  color: white;
  background-color: #ff3703;
  border: none;
  padding: 3% 10%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
}

.clientsInfo-btn:hover {
  color: #ff3703;
  background-color: white;
  border: 1px solid #ff3703;
  transition: all ease 500ms;
}