:root {
  /* Colors: */
  --unnamed-color-e8e8e8: #e8e8e8;
  --unnamed-color-f6f6f6: #f6f6f6;
  --unnamed-color-0258a417: #0258a417;
  --unnamed-color-000000: #000000;
  --unnamed-color-0258a4: #0258a4;
  --unnamed-color-25ac03: #25ac03;
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-ff3703: #ff3703;

  /* Font/text values */
  --unnamed-font-family-assistant: Assistant;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-28: 28px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-assistant);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-28);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}

html {
  width: 100%;
  height: 100%;
  font-size: 15px;
  /* font-size: 12px; */
}

body {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  /*font-family: "Rubik", sans-serif;*/
}

#root {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  #root {
    width: 100%;
    height: unset;
  }
}

@media (min-height: 650px) {
  #root {
    width: 100%;
    height: 100%;
  }
}

/* Pages */
.public-secure-routes {
  height: 100%;
  width: 100%;
}

.client-pages {
  display: flex;
  flex-direction: column;

  background-color: #f3f3f3;
  height: 100%;

  background-image: url("../src/assets/images/backgrounds/woman-in-beach-blurred.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.agent-pages {
  /* display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden; */
}

.admin-pages {
  background-color: #f3f3f3;
  height: 100%;
}

.agent-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
}

.agent-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
  overflow-y: auto;
}

.client-component {
  width: 100%;
  height: calc(100% - 150px);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #747474ad;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

/* ----- Media ----- */

.icon {
  width: 25px;
}

/* ----- Background Colors ----- */

.background-blue {
  background-color: #0258a4;
}

.background-green {
  background-color: #25ac03;
}

.background-orange {
  background-color: #ff3703;
}

/* ----- Colors ----- */

.font-blue {
  color: #0258a4;
}

.font-green {
  color: #25ac03;
}

.font-orange {
  color: #ff3703;
}

/* ----- Directions ----- */
.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

/* ----- Other ----- */
.cursor-pointer {
  cursor: pointer;
}

button {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

/* Animations */

@keyframes scroll-he {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Scroll Bar */
::-webkit-scrollbar {
  height: 1rem;
  width: 0.8rem;
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217, 217, 227, 0.8);
  border-color: rgba(255, 255, 255, 1);
  border-width: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

@media (max-width: 1024px) {
  .client-pages {
    height: unset;
  }

  .agent-component {
    padding: 0 5px;
    width: calc(100% - 10px);
  }
}
