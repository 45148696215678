.base-image-uploader-container {
  position: relative;

  .base-image-uploader-image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50px;
  }

  .base-image-uploader-input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
