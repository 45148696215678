.timer {
  color: red;
  font-weight: bold;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.time {
  white-space: pre;
}
