.table-container {
  margin: 0 5%;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.finance-widgets-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 25vh;
}

.validation-modal {
  background-color: white;
  height: auto;
  width: 20%;
  border-radius: 10px;
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1300;
  padding: 15px;
}

.validation-text {
  margin: auto;
  padding: 10px;
}

@media (max-width: 1024px) {
  .finance-widgets-container {
    height: unset;
    flex-direction: column;
  }

  .finance-widgets-container > .balance-widget {
    padding: 10px;
    width: calc(100% - 20px);
  }

  .finance-widgets-container > .balance-widget > .balance-number {
    font-size: 1.5rem;
  }
}
