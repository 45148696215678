.input-default {
  box-shadow: 0 2px 5px 0 #00000047 inset;
  border: 1px solid #b5b3b3;
  padding: 5px 12px;
  border-radius: 1000px;
}

.input-upload-default{
  box-shadow: 0 2px 5px 0 #00000047 inset;
  border: 1px solid #b5b3b3;
  padding: 2px 3px;
  border-radius: 1000px;
}
