.registration-box {
  margin: 5px;
  padding: 10px;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  height: calc(100% - 10px);
  box-sizing: border-box;
}
.registration-box-title {
  color: #0b68ba;
}
.registration-box-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 90%;
  overflow: auto;
}

@media (max-width: 1024px) {
  .registration-box {
    height: unset;
  }
}
