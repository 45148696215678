.searchResults-wrapper {
  /* display: flex;
  height: 100%;
  gap: 20px; */
}

.searchResults-item {
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /*gap: 30px;*/
  overflow-y: auto;
  /*direction: ltr;*/
}

.searchResults-filters-wrapper {
}

.change-layout {
  width: 100%;
}

.change-layout.hidden {
  display: none;
}

.layout-switch {
  border: none;
  background-color: transparent;
  outline: none;
  width: 50px;
  height: 50px;
}

.layout-switch img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.searchResults-results {
  flex: 1;
  gap: 20px;
  /*width: calc(100% - 200px);*/
  overflow-y: unset;
}

.searchResults-results.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.searchResults-results.cards .package-wrapper {
  padding: unset;
  width: calc(33.333% - 20px);
  min-width: 300px;
}

.searchResults-results .hotel-img {
  width: 20%;
  height: 200px;
  border-radius: 3px;
  object-fit: cover;
}

.searchResults-results.cards .hotel-img {
  width: 100%;
}

.searchResults-results.cards .center-hotel-data {
  padding: 20px !important;
}

.searchResults-results.cards .flight-footer-row {
  gap: 10px;
}

.searchResults-results.cards .flight-footer-row .info-section,
.searchResults-results.cards .flight-footer-row .luggage-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(32% - 10px);
  padding: 0 5px;
}

.searchResults-empty-results {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 130%;
}

.searchResults-loading-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .searchResults-results .hotel-img {
    width: 100%;
  }

  .searchResults-results.cards {
    justify-content: center;
  }

  .change-layout {
    display: none;
  }
}
