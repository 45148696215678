.successOrder-wrapper {
  background: rgba(243, 243, 243, 0.8);
  border-radius: 6px;
}

.successOrder-component {
  padding: 1.8% 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.successOrder-header-wrapper {
  display: flex;
  flex-direction: column;
}

.successOrder-header-title {
  background: #9ca402;
  color: white;
  font-weight: 600;
  padding: 0.5% 1%;
  font-size: 95%;
  border-radius: 3px;
}

.successOrder-header-hotelname {
  padding: 1%;
  color: #085f7b;
  font-weight: 600;
  font-size: 1.5rem;
  border-bottom: 2.5px solid #085f7b;
}

.successOrder-body-wrapper {
  height: 100%;
  background: white;
  border-radius: 3px;
}

.successOrder-body-gap {
  width: 1vw;
  background-color: #f3f3f3;
}

.successOrder-body {
  display: flex;
  flex-direction: column;
}

.successOrder-body-item-gap {
  width: 100%;
  height: 8px;
  background: #f3f3f3;
}

.successOrder-body-item-img {
  width: 100%;
  height: 300px;
  align-self: center;
  object-fit: cover;
}

.successOrder-content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  padding: 3% 3vw;
  gap: 10px;
  /* width: max-content; */
}

.successOrder-content-header-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e1e1e5;
  padding: 0 0 5px;
}

.successOrder-content-header-title-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.successOrder-content-header-title {
  color: #085f7b;
  font-weight: 600;
  font-size: 90%;
}

.successOrder-content-header-title-icon {
  color: #c59944;
  font-size: 85%;
}

.successOrder-content-header-sub-title {
  color: #797979;
  font-size: 87%;
}

.successOrder-content-header-sub-title-email {
  color: #085f7b;
  font-weight: 600;
  text-decoration: underline;
}

.successOrder-content-details-title {
  color: #085f7b;
  font-weight: 600;
  font-size: 1.5rem;
}

.successOrder-content-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 1rem;
}

.successOrder-content-details-detail {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.successOrder-content-details-detail-value {
  font-size: 1rem;
  color: #797979;
}

.successOrder-content-details-detail-value-green {
  font-size: 1rem;
  color: #25ac03;
}

.successOrder-content-details-detail-value-red {
  font-size: 1rem;
  color: red;
}

.successOrder-functions-wrapper {
  display: flex;
  flex-direction: column;
  // height: 100%;
}

.successOrder-functions-map-wrapper {
  height: 300px;
}

.successOrder-functions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3% 3vw;
  background-color: white;
  border-radius: 3px;
  // height: 100%;
}

.successOrder-functions-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5% 0;
}

.successOrder-function-title {
  font-size: 90%;
  color: #085f7b;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.successOrder-functions-canclation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.successOrder-functions-canclation-title {
  font-weight: 700;
  font-size: 95%;
}

.successOrder-functions-canclation-title-green {
  color: #25ac03;
}

.successOrder-functions-canclation-title-red {
  color: red;
}

.successOrder-functions-canclation-sub-title {
  color: #797979;
  font-size: 87%;
}

.successOrder-crubms-wrapper {
  display: flex;
  justify-content: center;
  background-color: #e3e3e3;
  padding: 3px 0;
}

.successOrder-crubms {
  width: 90%;
}

.successOrder-crubms-title {
  font-size: 85%;
  font-weight: 500;
}

.successOrder-crubms-title-link {
  color: #118ab2;
  text-decoration: underline;
  cursor: pointer;
}

.successOrder-cancelation-empty {
  width: 100%;
  /* padding: 15px 0; */
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 90%;
}

.successOrder-cancelation-desc {
  font-size: 70%;
  text-align: left;
  max-height: 10vh;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  direction: ltr;
}
