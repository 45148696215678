.button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  color: white;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.8);
  }
  &.disabled {
    background-color: grey;
    cursor: default;
    &:hover {
      filter: none;
    }
    &:active {
      filter: none;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: #0458a4;
  color: white;
  height: 30px;
  width: 50%;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.8);
  }
  &.disabled {
    background-color: grey;
    cursor: default;
    &:hover {
      filter: none;
    }
    &:active {
      filter: none;
    }
  }
}
.orange-button {
  background-color: #fe3702;
  display: flex;
  align-items: center;
}

.blue-button {
  background-color: #4081bb;
  display: flex;
  align-items: center;
}

.button-small {
  height: auto;
  padding: 1px 10px;
  align-items: center;
  display: flex;
}
.green-button {
  background-color: #65bb40;
}
.red-button {
  background-color: #bb4040;
}

.transparent-button {
  border: 2px solid #0458a4;
  background-color: white;
  color: #0458a4;
  font-weight: bold;
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .button,
  .button1 {
    width: unset;
    padding: 5px 10px;
  }
}
