.customAlertAgent-component {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #878787c9;
  z-index: 1000000000;
}

.customAlertAgent-wrapper {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  width: 25%;
}

.customAlertAgent-alert-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  color: white;
  background-color: #0258a4;
  box-shadow: 0px 4px 10px #726f6f;
  border-radius: 10px;
  padding: 3%;
  text-align: center;
}

.customAlertAgent-alert-title {
  font-weight: 700;
  font-size: 150%;
}

.customAlertAgent-alert-sub-title {
  font-size: 100%;
}

.customAlertAgent-alert-content-wrapper {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 10% 5%;
  box-shadow: 0px 4px 10px #726f6f;
  border-radius: 10px;
}

.customAlertAgent-alert-quit {
  color: white;
  background-color: #ff3703;
  border: none;
  padding: 3% 10%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  font-size: 105%;
}

.customAlertAgent-alert-quit:hover {
  color: #ff3703;
  background-color: white;
  border: 1px solid #ff3703;
  transition: all 500ms ease;
}
