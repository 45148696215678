@import "../../assets/variables";

.summary-footer {
  width: 100%;
  // height: 100%;
  color: white;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // height: 5vh;
  background-color: $steel_blue !important;
  // width: calc(100% - 80px);
  flex: 0 0 auto;
  box-shadow: 0 3px 10px 0 RGBA(0, 0, 0, 0.2);
  //background-color: white;
  // padding: 2px 3px;
  align-content: center;
  flex-wrap: wrap;

  .price-to-pay {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }

  .order-package-btn {
    color: white;
    font-weight: bolder;
    border: 1px solid white;
    background-color: $orange;
    &.disabled {
      background-color: grey;
      cursor: default;
    }
  }
}
