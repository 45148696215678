.google-login-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.google-logo {
  width: 25px;
  cursor: pointer;
}
