.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  white-space: nowrap;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  direction: ltr;
  font-size: 90%;
}

.dropdown-toggle img.language-flag {
  margin-right: 8px;
}

.arrow-icon {
  transform: rotate(90deg);
  font-size: 150%;
  padding: 0% 10%;
  margin-top: 4%;
}

.arrow-icon-up {
  transform: rotate(270deg);
}

.dropdown-menu {
  position: absolute;
  background-color: transparent;
  min-width: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-menu .dropdown-item {
  background: white;
  padding: 5% 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 110%;
  margin: 0;
  border: none;
  font-weight: 600;
  font-size: 80%;
}

.dropdown-item:hover {
  background-color: #f8f7f6;
}

.dropdown-menu .dropdown-item img.language-flag {
  margin-right: 8px;
}

.flag-icon {
  width: 25px;
  padding: 0 5px;
}
