@import "../../assets/variables";


.selected-package-details{
    width: 100%;
    padding:  0 3%;
    display: flex;
    flex-direction: column;
    gap: 5px;


    .hotel-city{
        color: $blue;
        font-weight: 700;
    }

    .hotel-name{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        gap: 10px;
        font-weight: 600;

    }

    .hotel-category{

    }


    .text-with-icon{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        gap: 10px;

        .orange-icon{
            color: $orange
        }

        .text{

        }
    }
}