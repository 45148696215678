.comments{
    line-height: 20px;
    &.focused{
      height: 150px;
    }
  }

.buttons-wrapper{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

  .input-button {
    color: white;
    background-color: #ff3703;
    border: none;
    padding: 3% 10%;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
    &.cancel{
        background-color: #ff3703;
    }
    &.save{
        background-color: #25ac02;;
      }
  }
  