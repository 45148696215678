.hotelSearchBar-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 95%;
  margin: auto;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  align-items: stretch;
}

.hotelSearchBar-item {
  background-color: #fff;
  border: 1px solid #b5b3b3;
  border-radius: 7.5px;
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
}

.hotelSearchBar-input-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.hotelSearchBar-input {
  border: none;
  width: 100%;
  font-size: 90%;
}

.hotelSearchBar-input:focus {
  outline: none;
}

.hotelSearchBar-input-location {
  cursor: pointer;
}

.hotelSearchBar-desc-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 5;
  width: 100%;
  overflow: hidden;
}

.hotelSearchBar-label-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hotelSearchBar-label1 {
  /*font-weight: 500;*/
  font-size: 85%;
  white-space: nowrap;
}

.hotelSearchBar-label2 {
  font-weight: 400;
  font-size: 75%;
  white-space: nowrap;
  /* animation: scroll-he 20s linear infinite; */
}

.hotelSearchBar-letter-spacing {
  letter-spacing: 1px;
}

.hotelSearchBar-icon-wrapper {
  display: flex;
  margin: auto;
}

.hotelSearchBar-icon {
  width: 25px;
}

.hotelSearchBar-icon2 {
  width: 20px;
  height: 20px;
}

.hotelSearchBar-calendar-required {
  opacity: 0;
  right: 5%;
  top: 40%;
  position: absolute;
  z-index: 0;
}

.hotelSearchBar-location-required-wrapper {
  position: relative;
  display: flex;
}

.hotelSearchBar-location-required {
  opacity: 0;
  right: 0%;
  top: 30%;
  position: absolute;
  z-index: 10000;
}

.autoComplete-input-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative !important;
  width: 100%;
  position: relative;
  z-index: 100;

  .autoComplete-input-options {
    max-height: 400px;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;

    .autoComplete-input-option-row {
      width: calc(100% - 20px);
      height: 40px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 5px 10px;
      &:hover {
        background-color: #d7d7d7;
      }
      &:active {
        background-color: #b9b9b9;
      }
    }
  }
}

.autoComplete-input-box {
  border: none;
  width: 100%;
  font-size: 90%;
  outline: none;
  position: relative !important;
}

.pac-container {
  // width: 25% !important;
  /* left: 50% !important; */
}

.ltr-pac-container {
  width: 25% !important;
  /* left: 70% !important; */
}

.pac-container .pac-item:hover {
  cursor: pointer; /* Apply pointer cursor on hover to items */
}

.hotelSearchBar-item-calender {
  min-width: 11rem;
}
