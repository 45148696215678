.orders-filters {
  width: 70%;
  /* position: relative; */
}

@media (max-width: 1024px) {
  .orders-filters {
    width: 95%;
  }
}
