.priceOffer-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.priceOffer-change-client-wrapper {
  width: max-content;
  white-space: nowrap;
}

.priceOffer-change-client {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  height: 100%;
  /* padding: 0 5%; */
}

.priceOffer-change-client-img {
  width: 30px;
}

.priceOffer-change-client-title {
  font-size: 95%;
  font-weight: 500;
}

.priceOffer-change-client-title:hover {
  color: #ff3703;
  transition: all 500ms ease;
}

.priceOffer-component-wrapper {
  width: 100%;
  height: 100%;
}
