.date-range-wrapper {
  display: flex;
  /* gap: 3%; */
  position: relative;
  cursor: pointer;
  width: 240px;
  align-items: center;
}

.date-range-calendar-image {
  width: 20px;
  height: 20px;
}

.date-range-calendar-image-wrapper {
  display: flex;
  margin: auto;
}

.date-range-inputs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 5;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.date-range-dates {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-range-date-separator {
  color: grey;
}

.date-range-date {
  font-size: 14px !important;
  white-space: nowrap;
}

.first-date {
  font-weight: 600;
}

.dates-range-input {
  opacity: 0;
  right: 5%;
  top: 40%;
  position: absolute;
  z-index: 0;
}
