.orders-table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.orders {
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .orders {
    width: 100%;
  }
}
