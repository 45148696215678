.summeryClientsInfo-wrapper {
  display: flex;
  flex-direction: column;
}

.summeryClientsInfo-text1 {
  font-size: 75%;
  padding: 0 2%;
}

.summeryClientsInfo-text2 {
  font-size: 85%;
  font-weight: 700;
  padding: 0 2%;
}
.summeryClientsInfo-details-wrapper-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 1%;
  padding: 1% 2%;
}

.summeryClientsInfo-details-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.summeryClientsInfo-title {
  font-weight: 700;
  font-size: 85%;
  color: #0258a4;
  letter-spacing: -0.2px;
}

.summeryClientsInfo-details-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2% 0 5%;
  width: 33%;
}

.summeryClientsInfo-details-icon {
  width: 10px;
}

.summeryClientsInfo-details-title {
  font-weight: 700;
  font-size: 80%;
  width: max-content;
}

.summeryClientsInfo-details-value {
  font-size: 80%;
  color: #3177b5;
}

.summeryClientsInfo-input-label-overflow-wrapper {
  /* overflow-x: hidden; */
  /* overflow-x: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.summeryClientsInfo-input-label-overflow {
  /* overflow-x: hidden;
    width: 100%; */
  /* animation: scroll 2s linear infinite;  */
}
