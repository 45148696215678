.filters-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.package-filters-title {
  font-weight: 700;
  margin: 10px 0;
  padding: 0 5%;
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  height: 85%;
  gap: 10px;
}

.filters-reset-btn-wrapper {
  display: flex;
  width: 100%;
  align-self: center;
  // height: 35px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .reset-btn {
    border-radius: 3px;
    color: white;
    background-color: #ff5427;
    border: none;
    width: max-content;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px 10px;
    height: 100%;
  }
}
