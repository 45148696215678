.customLoading-component {
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 5%;
  width: max-content;
  font-size: 80%;
  align-items: center;
  background-color: #eae9f1;
  border-radius: 7px;
  color: #6e6c6b;
  border: 1px solid #1063ac;
  justify-content: center;
}
