.upper-nav-bar-agent-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 20px;

  background-color: #ffffff;
  /*list-style-type: none;*/
  /*display: flex;*/
  margin: 0;
  box-shadow: 0 2px 4px #9e9b9b;
  align-items: center;
  /*height: 100%;*/
}

.upper-nav-bar-agent {
  background-color: #ffffff;
  list-style-type: none;
  display: flex;
  margin: 0;
  box-shadow: 0px 2px 4px #9e9b9b;
  align-items: center;
  height: 100%;
}

.upper-nav-bar-agent li {
  margin-right: 2%;
  height: fit-content;
}

.orange-button-agent {
  background: transparent;
  color: #ff3703;
  border-color: #ff3703;
  border-style: solid;
  padding: 5% 10%;
  border-radius: 20px;
  cursor: pointer;
  width: max-content;
  font-weight: 500;
}

.orange-button-agent:hover {
  background: #ff3703;
  color: white;
  transition: all ease 0.5s;
}

.logo-nav-agent {
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0.5% 8%;
  font-weight: 600;
}

.logo-img-wrapper {
  /*display: flex;*/
  /*justify-content: end;*/
  /*width: 100%;*/
  height: 100%;
}

.logo-img {
  height: 100%;
  max-height: 55px;
  object-fit: contain;
  margin: 0 5px;
}

.open-drawer-button {
  display: none;
}

.right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.menu {
  display: none;
}

@media (max-width: 1024px) {
  .upper-nav-bar-agent-wrapper {
    margin-right: unset !important;
    width: calc(100% - 40px) !important;
  }

  .open-drawer-button {
    display: block;
    border: none;
    outline: none;
    background: transparent;
  }

  .open-drawer-button > svg {
    font-size: 1.5rem;
  }

  .menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 4px #9e9b9b;
    width: 100%;
    z-index: 999;
    height: calc(100vh - 60px);
    overflow-y: auto;
  }

  .menu > .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px);
    padding: 10px 20px;
    gap: 20px;
    cursor: pointer;
  }

  .menu > .menu-item.disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    color: #9e9b9b;
  }

  .menu > .menu-item > img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
}
