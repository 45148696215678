.hotelInfo-wrapper {
  /* padding: 0 3% */
}

.hotelInfo-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hotelInfo-title {
  padding: 2%;
  background: #ffdfd6;
  font-weight: 400;
  flex-direction: row;
  gap: 5px;
  display: flex;
  font-size: 16px;
  justify-content: center;
}

.hotelInfo-title-text {
  font-weight: 500;
}

.hotelInfo-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  gap: 5px;
  margin-top: 1%;
}

.hotelInfo-rooms-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.hotelInfo-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 30%;  */
}

.hotelInfo-img {
  /*height: 10vh;*/
  height: 100%;
  max-height: 150px;
  border-radius: 3px;
}

.hotelInfo-info-wrapper {
  display: flex;
  flex-direction: row;
  /*gap: 7.5px;*/
  /*width: auto;*/
  justify-content: space-around;
  width: 100%;
}

.hotelInfo-info-wrapper-item {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /*width: fit-content;*/
  gap: 5px;

}

.hotelInfo-info-wrapper-item-item-title {
  width: max-content;
  font-size: 85%;
  font-weight: 600;
  position: relative;
}

.hotelInfo-info-wrapper-item-item {
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
}

.hotelInfo-info-wrapper-item-item-value {
  /* width: max-content;
    white-space: nowrap; */
  font-size: 75%;
  color: #1063ac;
  font-weight: 400;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  max-width: 15vw;
  text-align: right;
}

.hotelInfo-info-wrapper-item-item-value-room-name {
  width: 15vw;
}

.hotelInfo-info-wrapper-item-item-title-numOfNights {
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
}

.hotelInfo-info-wrapper-item-item-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  width: max-content;
}

/* --- Pricing --- */
.hotelInfo-pricing-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: auto;
}

.hotelInfo-pricing-title {
  font-size: 80%;
  color: #0258a4;
  width: max-content;
  font-weight: 500;
}

.hotelInfo-pricing-sub-title {
  color: #0258a4;
  font-weight: 600;
  text-align: start;
}

.hotelInfo-title-value {
  font-weight: 600;
  color: black;
}

.hotelInfo-content-room-name {
  position: absolute;
  background: white;
  right: -125%;
  color: black;
  z-index: 1;
  padding: 25%;
  box-shadow: 0px 2px 4px #b5b3b3;
  border-radius: 5px;
  cursor: pointer;
}

.hotelInfo-content-room-fullname {
  position: absolute;
  background: #ffdfd6;
  right: -285%;
  color: black;
  padding: 5%;
  border-radius: 2px;
  cursor: pointer;
}

.hotelInfo-more-rooms {
  letter-spacing: 2px;
  border: 1px solid #1063ac;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 110%;
  padding: 0px 5px;
}

.hotelInfo-info-wrapper-item-similar {
  font-weight: 600;
  color: black;
}

.hotelInfo-empty-order {
  text-align: center;
  font-weight: 500;
}
