.price-offer {
}

.price-offer-data-wrappers {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #b5b3b3;
}

.price-offer-content-wrapper {
  /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%; */
  /* height: calc(100vh - 100px); */
}

/* CheckBoxes */
.checkboxs-wrapper {
  display: flex;
}

.flight-x-way-checkbox-wrapper {
  display: flex;
}

.flight-x-way-checkbox {
  position: relative;
}

.flight-x-way-checkbox label {
  background-color: #fff;
  border: 4px solid #ff3703;
  border-radius: 50%;
  cursor: pointer;
  height: 35%;
  position: absolute;
  width: 35%;
}

.flight-x-way-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.flight-x-way-checkbox input[type="checkbox"]:checked + label {
  background-color: #ff3703;
  border-color: #ff3703;
}

.flight-x-way-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.flight-x-way-span {
  margin: 0 20px;
  font-weight: 700;
  font-size: 80%;
}

/* Search bar */
.price-offer-search-bar-wrapper {
  height: 12.5%;
  margin-top: 1%;
}

/* Content */
.price-offer-contect-wrapper {
  /*todo(sagiv): Remove?*/
  /*display: flex;*/
  /*gap: 2%;*/
  height: calc(100% - 100px);
}

.price-offer-contect-wrapper-wide {
  height: 87.5%;
}

.price-offer-no-results-wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff60;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.price-offer-no-results-img {
  width: 12.5%;
  opacity: 0.8;
}

.price-offer-filters-wrapper {
  height: 100%;
  width: 10%;
  border-radius: 7.5px !important;
}

.price-offer-f-h-wrapper {
  height: 100%;
  width: 65%;
  border-radius: 7.5px !important;
  overflow-y: scroll;
}

.price-offer-order-details-wrapper {
  height: 100%;
  border-radius: 7.5px !important;
  background-color: #f3f3f3;
}

.price-offer-f-h {
  width: 100%;
  /* height: 35%; */
  margin-bottom: 5%;
  display: flex;
  flex-direction: row-reverse;
}

.price-offer ::-webkit-scrollbar {
  width: 14px;
}

.price-offer ::-webkit-scrollbar-track {
  background-color: transparent;
}

.price-offer ::-webkit-scrollbar-thumb {
  background-color: #ff3703;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.price-offer ::-webkit-scrollbar-thumb:hover {
  background-color: #ff7451;
}

/* ----- Flight info -----*/
.flight-info-wrapper {
  padding: 2%;
  padding-top: 3.5%;
  width: 62.5%;
  box-shadow:
    -5px 0 3px -5px #b5b3b3,
    5px 0 4px -5px #b5b3b3;
}

.flightInfo-Row {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2%;
  gap: 3.5%;
  font-size: 77.5%;
}

.flightInfo-icon-wrapper {
  margin: auto;
}

.flightInfo-icon {
  width: 35px;
}

.flightInfo-country-time-wrapper {
  display: flex;
  flex-direction: column;
  width: 110px;
  /* Adjust the width as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flightInfo-country-time-title {
  direction: rtl;
  display: inline-block;
}

.flightInfo-country-time-wrapper:hover .flightInfo-country-time-title {
  display: none;
  /* Adjust animation duration as desired */
}

.flightInfo-country-time-hover-title {
  display: none;
}

.flightInfo-country-time-wrapper:hover .flightInfo-country-time-hover-title {
  display: block;
  direction: rtl;
  animation: scroll-he 3.5s linear infinite;
  /* Adjust animation duration as desired */
}

.flightInfo-country-time-subtitle {
  font-weight: 700;
  direction: rtl;
}

.flightInfo-arrow {
  margin: auto;
  font-size: 190%;
  color: #8e8e8e;
}

.flightInfo-stops {
  font-weight: 700;
  white-space: nowrap;
}

.flightInfo-flight-time {
  font-weight: 700;
  white-space: nowrap;
}

/* ----- Details ----- */

.flight-info-details-wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 2%;
}

.flight-info-details-item {
  width: 100%;
  white-space: nowrap;
  padding: 0 3%;
  font-size: 85%;
  margin: auto;
}

.flight-info-details-time {
  font-weight: 700;
}

.flight-info-more-details {
  display: flex;
  gap: 5%;
  font-weight: 700;
  color: #0258a4;
  cursor: pointer;
  text-align: -webkit-match-parent;
  margin-top: 2%;
}

.flight-info-more-details:hover {
  color: #1d7acd;
}

.flight-info-arrow-icon {
  display: inline-block;
  transform: rotate(90deg);
  font-size: 150%;
  padding: 0% 2%;
  margin-top: -1%;
}

.flight-info-arrow-icon-up {
  transform: rotate(270deg);
}

/* ----- Pricing -----*/
.flight-info-pricing-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2%;
  font-size: 90%;
  width: 37.5%;
}

.flight-info-pricing-travler-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
}

.flight-info-pricing-agent-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.flight-info-pricing-agent-title {
  font-size: 95%;
}

.flight-info-pricing-travler-price {
  font-size: 130%;
  color: #0258a4;
  font-weight: 700;
}

.flight-info-pricing-agent-price {
  font-weight: 600;
}

.flight-info-buttons-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 3.5%;
  width: 100%;
}

.flight-info-button {
  cursor: pointer;
  border: none;
  border-radius: 20px;
  color: white;
  font-weight: 699;
  padding: 5% 6%;
  margin: auto;
  font-size: 77.5%;
  min-width: fit-content;
}

.flight-info-button-blue {
  background-color: #0258a4;
}

.flight-info-button-blue:hover {
  background-color: white;
  color: #0258a4;
  border: 2px solid #0258a4;
  transition: all ease 500ms;
}

.flight-info-button-orange {
  background-color: #ff3703;
}

.flight-info-button-orange:hover {
  background-color: white;
  color: #ff3703;
  border: 2px solid #ff3703;
  transition: all ease 500ms;
}

/* ----- Expended ----- */

.flight-info-extended-wrapper {
  display: flex;
  flex-direction: column;
  height: 60%;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 5%;
  box-shadow: 0px 2px 10px #b5b3b3;
  border: 2px solid #ff3703;
  transition: all ease 80ms;
}

.flight-info-extended-content {
  height: 100%;
  padding: 2% 5%;
  margin: 0 2%;
  box-shadow: 0 -5px 3px -5px #b5b3b3;
}

.price-offer-change-user-search-option-wrapper {
  display: flex;
  padding: 0 10px;
  width: calc(100% - 20px);
  justify-content: space-between;
  align-items: center;
}

.price-offer-search-option-wrapper-wrapper {
  width: 100%;
}

.price-offer-order-summery-narrow {
  width: 10%;
  height: 100%;
  background-color: #ffffff60;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.price-offer-order-summery-narrow-button {
  font-size: 175%;
  cursor: pointer;
}

.price-offer-order-summery-narrow-button:hover {
  opacity: 0.7;
}

.price-offer-passenger-error {
  width: 100%;
  font-weight: 600;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}
