.checkbox-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .checkbox-input {
    border: transparent;
    transform: scale(1.5);
    background-color: transparent;
    margin: 0 10px;
  }

  