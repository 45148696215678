@import "../../assets/variables";

.room-selector-table{
    width: 100%;
    text-align: right;
    border-bottom: 1px solid #dee2e6;
    
    .room-selector-table-head {
        padding-bottom: 10px;
    }
    
    .room-selector-row{
        height: 35px;
        cursor: pointer;
        &.selected{
            background-color: $green_background;
        }
        &.disabled{
            cursor: default;
        }
        .auto-approve{
            color: $green;
            font-weight: 700;
        }
        .room-selector-radio-btn{
            -ms-transform: scale(1.5); /* IE 9 */
            -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
            transform: scale(1.5);
            margin:  0 20px ;
        }
    }
}