.main-page {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: calc(100% - 120px);
  padding: 0 60px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.7) 30%,
    rgba(255, 255, 255, 0) 100%
  );

  .title {
    font-size: 50px;
    margin-bottom: 20px;
    color: black;
    font-weight: bold;
    text-shadow:
      -1px -1px 0 #fff,
      1px -1px 0 #fff,
      -1px 1px 0 #fff,
      1px 1px 0 #fff;
  }
  .sub-title {
    color: black;
    font-size: 30px;
    font-weight: normal;
    text-shadow:
      -0.5px -0.5px 0 #d9d9d9,
      0.5px -0.5px 0 #d9d9d9,
      -0.5px 0.5px 0 #d9d9d9,
      0.5px 0.5px 0 #d9d9d9;
  }
}

@media (max-width: 1024px) {
  .main-page {
    height: unset !important;
    width: 100%;
    padding: 20px 40px !important;
  }
  .sub-title {
    font-size: 25px;
  }
}
