.payment-card-details{


  .payment-error{
    color:red;
    display: flex;
    flex-direction: row;
    gap: 5px;

  }
}