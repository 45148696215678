.registration-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 15px 0;

    .registration-title{
        white-space: nowrap;
        color: #0458a4;
        font-weight: bold;
        font-size: 18px;
    }
    .registration-title-line{
        height: 1px;
        width: calc(100% - 80px);
        background-color: #0458a4;
        margin: 0 40px;
    }
}
