.roomPassInfo-component {
  background-color: white;
  border-radius: 10px;
  padding: 3% 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.roomPassInfo-component-item {
  width: 90%;
}

.roomPassInfo-main-title-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 3%;
  direction: rtl;
}

.roomPassInfo-title-img-wrapper {}

.roomPassInfo-title-img {
  width: 25px;
}

.roomPassInfo-hotel-name-wrapper {}

.roomPassInfo-hotel-name {
  color: #4081ba;
  font-weight: 600;
}

.roomPassInfo-title-wrapper {
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1%;
}

.roomPassInfo-border {
  width: 100%;
  height: 0;
  border: 1px solid #d9d9d9;
}

.roomPassInfo-title {
  width: max-content;
  white-space: nowrap;
  font-weight: 700;
  color: #ff3703;
  font-size: 95%;
}

.roomPassInfo-passenger {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.roomPassInfo-passenger-info-title {
  color: #4081ba;
  font-weight: 600;
  font-size: 77%;
}

.roomPassInfo-passenger-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.roomPassInfo-passenger-info-item {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  width: 45%;
}

.roomPassInfo-main-passenger-info-note {
  color: #232323;
  font-size: 60%;
  font-weight: 500;
}

.roomPassInfo-rest-of-passengers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.roomPassInfo-passenger-info-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.roomPassInfo-passenger-info-button {
  color: white;
  background-color: #0258a4;
  border: none;
  cursor: pointer;
  font-size: 75%;
  padding: 1% 2%;
  border-radius: 3px;
}

.roomPassInfo-passenger-info-button:hover {
  color: #0258a4;
  background-color: white;
  border: 1px solid #0258a4;
  transition: all 500ms ease;
}

.roomPassInfo-duplicate-lastname-room2etc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;
}