.hotelObject-wrapper {
  display: flex;
  /*flex-direction: row-reverse;*/
  height: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #b5b3b3;
}

.cards .hotelObject-wrapper {
  display: flex;
  flex-direction: column;
  height: unset !important;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #b5b3b3;
}

.hotelObject-info-wrapper {
  display: flex;
  width: 75%;
}

.cards .hotelObject-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hotelObject-display-image-wrapper {
  padding: 2%;
  width: 45%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards .hotelObject-display-image-wrapper {
  width: 100%;
  height: 50%;
  padding: unset;
  margin: unset;
  margin-bottom: 2%;
}

.hotelObject-display-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  max-height: 150px;
  border-radius: 5px;
  cursor: zoom-in;
  object-fit: cover;
}

.cards .hotelObject-display-image {
  border-radius: 5px 5px 0 0;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.hotelObject-display-image-zoom-wrapper {
  background: #747474ad;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  margin: auto;
  justify-content: center;
  display: flex;
}

.hotelObject-display-image-zoom {
  cursor: zoom-out;
  border-radius: 0;
  padding: 5%;
}

.hotelObject-description-wrapper {
  display: flex;
  flex-flow: column;
  width: 85%;
  padding: 2%;
  box-shadow:
    -5px 0 2.5px -5px #b5b3b3,
    0px 0 0px 0px #b5b3b3;
}

.cards .hotelObject-description-wrapper {
  box-shadow: none;
  width: calc(100% - 20px);
  padding: 10px;
}

.hotelObject-description {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.hotelObject-title-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: -1%;
  margin-bottom: 2%;
  direction: ltr;
}

.hotelObject-title {
  font-weight: 700;
  color: #0258a4;
}

.cards .hotelObject-title {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.hotelObject-sub-title {
  font-size: 12px;
  color: #656464;
  margin-top: -0.5%;
}

.hotelObject-distance {
  font-size: 12px;
  color: blue;
  margin-top: 5px;
  direction: rtl;
  text-align: right;
}

.cards .hotelObject-sub-title {
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.hotelObject-text {
  font-size: 80%;
  font-weight: 600;
}

.hotelObject-share {
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 2px;
  margin-top: auto;
}

.hotelObject-share-text {
  font-size: 80%;
  font-weight: 500;
}

.hotelObject-icon-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1%;
}

.hotelObject-icon {
  width: 20px;
  cursor: pointer;
}

.hotelObject-pricing {
  width: 30%;
}

.hotelObject-nobedimage-wrapper-wrapper {
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2%;
}

.hotelObject-nobedimage-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 77.5%;
}
