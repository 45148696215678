.roomPlacer-component {
  font-size: 90%;
}

.roomPlacer-suggested-room-wrapper {
  border: 1.5px solid #ff3703;
  margin-top: 2%;
  width: auto;
  border-radius: 5px;
  padding: 1%;
  margin-bottom: 5%;
}

.roomPlacer-regular-room-wrapper {
  border: 1.5px solid #dcdcdc;
  width: auto;
  border-radius: 5px;
  padding: 1%;
}

.roomPlacer-suggested-room-wrapper:hover {
  box-shadow: 0px 2px 4px #ff9535;
  transition: all 500ms ease;
}

.roomPlacer-no-rooms {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 10% 0;
  font-size: 130%;
}

.roomPlacer-nobedimage-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10%;
  text-align: center;
  height: 18vh;
}

.roomPlacer-nobedimage-img {
  width: 50%;
  opacity: 0.7;
}

.roomPlacer-nobedimage-title {
  color: #707070;
  font-size: 75%;
}

.roomPlacer-agent-commition-wrapper {
  width: 60%;
  display: flex;
  position: relative;
}

.roomPlacer-agent-commition {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000 !important;
  border: none;
  outline: none;
}

.roomPlacer-agent-commition-percent {
  position: absolute;
  right: 10px;
  top: 0px;
}

/* Other */
.roomDisplayer-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.roomDisplayer-title {
  font-weight: 600;
}

.roomDisplayer-rooms-wrapper {
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  padding: 0 5%;
}

.roomDisplayer-room-packages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  /* width: 85%; */
  height: fit-content;
  margin: auto;
  margin-top: 4%;
}

.roomDisplayer-room-package {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: fit-content;
}

.roomDisplayer-room-wrapper {
  width: 100%;
}

.roomDisplayer-room-package-header {

  gap: 10px;
  padding: 2%;
}

.roomDisplayer-room-package-describtion {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  height: 200px;
}

.roomDisplayer-room-package-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #b5b3b3;
  justify-content: end;
}

.roomDisplayer-room-package-title {
  font-size: 115%;
  font-weight: 500;
  color: #ff7451;
  display: flex;
  flex-direction: row-reverse;
  font-weight: 700;
}

.roomDisplayer-room-package-sub-title {
  color: #25ac03;
  font-weight: 400;
  font-size: 100%;
}

.roomDisplayer-room-package-amenatis {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 50%;
  margin-top: 10px;
}

.roomDisplayer-room-package-amenatis-title {
  font-weight: 700;
  font-size: 90%;
}

.roomDisplayer-room-all-rooms {
  flex-direction: row;
  display: flex;
  width: auto;
  /* border: 1px solid #dcdcdc; */
  border-radius: 5px;
  /* padding: 5% 0%; */
  gap: 10px;
  align-items: center;
  justify-content: center;
}

/* .roomDisplayer-room-all-rooms:hover {
    border-color: #ff3703;
    transition: all 500ms ease;
} */

.roomDisplayer-room-content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 3%;
}

.roomDisplayer-room-object-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 0 auto;
}

.roomDisplayer-object-room-border {
  width: 0.1px;
  background: #efeeee;
}

.roomDisplayer-object-pricing {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 3%;
  justify-content: center;
}

.roomDisplayer-object-cancelation-title {
  font-weight: 700;
  font-size: 85%;
}

.roomDisplayer-object-cancelation-sub-title {
  font-size: 70%;
  font-weight: 500;
}

.roomDisplayer-more-option-wrapper {
  justify-content: center;
  display: flex;
}

.roomDisplayer-more-option-btn {
  border: none;
  padding: 1% 0;
  width: 32.5%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 100%;
  font-weight: 500;
  background-color: #b5b3b396;
}

.roomDisplayer-more-option-btn:hover {
  background: #a8a4a446;
  transition: all 500ms ease;
}

.roomDisplayer-object-room-devider {
  border: 1px dashed #ff7451;
}

.suggestedRoom-wrapper {
  width: 100%;
}

.suggestedRoom-object {
  border: 1.5px solid #dcdcdc;
  display: flex;
  flex-direction: row;
  padding: 5%;
  gap: 5%;
  width: auto;
  border-radius: 5px;
}

.suggestedRoom-object-rooms {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.suggestedRoom-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.suggestedRoom-title {
  color: #25ac03;
  font-weight: 700;
  font-size: 115%;
}

.suggestedRoom-sub-title {
  font-weight: 500;
  font-size: 90%;
}

.suggestedRoom-object-room {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.suggestedRoom-object-border {
  border: 1px solid #e8e8e8;
}

.suggestedRoom-object-room-border {
  height: 50%;
  border: 1px solid #efeeee;
}

.suggestedRoom-object-room-img {
  width: 50%;
  margin: auto;
  direction: ltr;
}

.suggestedRoom-object-room-img-zoom-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 100;
  background: #747474ad;
  justify-content: center;
  display: flex;
  align-items: center;
}

.suggestedRoom-object-room-img-zoom {
  padding: 5%;
  width: 60vw;
  height: fit-content;
}

.suggestedRoom-object-room-img-zoom-cursor {
  cursor: zoom-out !important;
}

.suggestedRoom-object-describtion-wrapper {
  display: flex;
  flex-direction: column;
  direction: ltr;
  width: 35%;
}

.suggestedRoom-object-describtion-title {
  color: #ff5427;
  font-size: 100%;
  font-weight: 600;
}

.suggestedRoom-object-describtion-sub-title {
  color: #1063ac;
  font-weight: 400;
  font-size: 90%;
}

.suggestedRoom-object-cancelation-wrapper {
  display: flex;
  flex-direction: column;
}

.suggestedRoom-object-cancelation {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}

.suggestedRoom-object-cancelation-img {
  width: 25px;
  height: 25px;
  opacity: 0.5;
}

.suggestedRoom-object-cancelation-title {
  font-weight: 700;
  font-size: 95%;
}

.suggestedRoom-object-cancelation-title-refund {
  color: #25ac03;
}

.suggestedRoom-object-cancelation-title-no-refund {
  color: red;
}

.suggestedRoom-object-cancelation-sub-title {
  font-size: 80%;
  font-weight: 500;
  max-width: 200px;
  min-width: 60px;
}

.suggestedRoom-object-pricing-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto;
  align-items: center;
}

.suggestedRoom-object-pricing {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.suggestedRoom-object-pricing-title {
  color: #0258a4;
  font-weight: 700;
}

.suggestedRoom-object-pricing-sub-title {
  font-size: 75%;
  font-weight: 500;
  width: max-content;
}

.suggestedRoom-object-pricing-btn {
  color: white;
  background: #ff3703;
  border: none;
  border-radius: 4px;
  padding: 3px 10px;
  width: max-content;
  cursor: pointer;
  white-space: nowrap;
}

.suggestedRoom-object-pricing-btn:hover {
  color: #ff3703;
  background: white;
  border: 1px solid #ff3703;
  transition: all 500ms ease;
}

.roomDisplayer-object-select-package {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
}