.splitPayment-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  gap: 5px;
}

.splitPayment-sub-wrapper {
  gap: 2vh;
}

.splitPayment-card-amount-wrapper-wrapper {
  /* height: 15%; */
  width: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.splitPayment-card-amount-wrapper {
  height: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
}

.splitPayment-card-amount-title {
  font-size: 80%;
  width: max-content;
  white-space: nowrap;
}

.splitPayment-card-amount-button {
  background: #25ac03;
  color: white;
  display: flex;
  border-radius: 5px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
}

.splitPayment-card-amount-input {
  width: 24px;
  height: 15px;
  font-size: 90%;
  font-weight: 500;
  border-radius: 3px;
  outline: none;
  border: none;
  direction: rtl;
}

.splitPayment-card-amount-totalprice {
  width: fit-content;
  justify-content: end;
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passangerData-submit-button-pay-later {
  background: #0258b6;
  color: white;
  border: none;
  padding: 7.5px 15px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  width: max-content;
}

.splitPayment-card-amount-price-title {
  /* width: max-content;
    white-space: nowrap; */
  color: #0258a4;
  font-weight: 700;
}

.splitPayment-card-amount-price-number {
  font-size: 110%;
}

/* Card Displayer */

.splitPayment-card-extand-wrapper {
  border: 1px solid #777777;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1% 7%;
  cursor: pointer;
}

.splitPayment-card-extand-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.splitPayment-card-extand-img {
  width: 22px;
}

.splitPayment-card-extand-title {
  font-size: 90%;
  font-weight: 400;
}

.splitPayment-card-displayer {
  height: 85%;
  /* background-color: white; */
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* overflow-y: auto;
    overflow-x: hidden; */
}
