@import "../../assets/variables";
.input-container {
  margin: 5px 15px;

  .title {
    color: $orange;
    margin-bottom: 5px;
  }
  .input {
    padding: 2px 8px;
    height: 25px;
    border-radius: 6px;
    border: 1px solid grey;
    width: 240px;
    box-sizing: content-box;
  }
}

@media (max-width: 1024px) {
  .input-container {
    width: calc(100% - 30px);
    .input {
      padding: 2px 8px;
      height: 25px;
      border-radius: 6px;
      border: 1px solid grey;
      width: calc(100% - 16px);
      box-sizing: content-box;
    }
  }
}
