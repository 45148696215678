.hotelFilters-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hotelFilters-title {
  font-weight: 700;
  /* font-size: 90%; */
  margin-top: 5%;
  padding: 0 5%;
}

.hotelFilters-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  height: 100%;
  font-size: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

.hotelFilters-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hotelFilters-filters-title-wrapper {
  background: #0258a4;
  padding: 2% 3%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hotelFilters-filters-title {
  font-weight: 600;
  color: white;
  padding: 0 5%;
}

.hotelFilters-filters-arrow {
  font-weight: 600;
  color: white;
}

.hotelfilters-star-rating-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  margin: auto;
}

.hotelfilters-price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 85%;
  margin: auto;
  direction: ltr;
}

.hotelfilters-price-values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 90%;
}

.hotelfilters-star-rating-item {
  box-shadow: 0px 1px 3px #b5b3b3;
  padding: 3% 6%;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 90%;
}

.hotelfilters-star-rating-clicked {
  font-size: 95%;
  color: white;
  background-color: #ff3703;
  transition: all 50ms ease;
}

.hotelfilters-reset-btn-wrapper {
  margin: auto;
  padding: 5% 0;
  display: flex;
  justify-content: center;
}

.hotelfilters-reset-btn {
  border-radius: 3px;
  color: white;
  background-color: #ff5427;
  border: none;
  padding: 7.5% 16%;
  width: max-content;
  white-space: nowrap;
  cursor: pointer;
}

.hotelfilters-reset-btn:hover {
  color: #ff5427;
  background-color: white;
  border: 1px solid #ff5427;
  transition: all 500ms ease;
}

.hotelfilters-name-filter-input {
  background-color: #e8e8e87a;
  border: none;
  outline: none;
  margin-bottom: 5%;
  padding: 4px 2px;
}

.hotelfilters-meal-checkboxs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
}

.hotelfilters-meal-checkbox-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.hotelfilters-meal-checkbox-title {
  font-size: 85%;
  font-weight: 500;
}

.hotelfilters-meal-checkbox {
  cursor: pointer;
  width: 10px;
}

.hotelFilters-wrapper ::-webkit-scrollbar {
  width: 6px;
}

.hotelFilters-wrapper ::-webkit-scrollbar-thumb {
  background-color: #ff3703;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.hotelfilters-price-values-title {
  width: max-content;
}

.hotelfilters-price-sort-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 3px;
}

.hotelfilters-price-sort-radio {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.hotelfilters-price-sort-radio-input {
  cursor: pointer;
}

.hotelfilters-price-sort-radio-title {
  font-size: 90%;
}

.hotelfilters-star-sort-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px !important;
  gap: 3px;
  margin: 0 7%;
}