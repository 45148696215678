.same-room-duplication {
  color: black;
}

.roomDisplayer-object-basis {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.roomDisplayer-object-basis-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.roomDisplayer-object-basis-title {
  font-weight: 600;
  color: #25ac03;
  min-width: 8vw;
}

.roomDisplayer-object-basis-img {
  width: 30px;
}

.roomDisplayer-room-content-wrapper {
  display: flex;
  flex-direction: column;
  /* Display table as a column */
  width: 100%;
}

.roomDisplayer-room-content-wrapper tbody {
  flex: 1;
  /* Allow tbody to take up remaining space */
  display: flex;
  flex-direction: column;
  /* Display tbody as a column */
  width: 100%;
}

.roomDisplayer-room-content-wrapper tr {
  width: 100%;
  display: flex;
  /* Display tr as a flex container */
  flex-direction: row;
  /* Display tr as a row within tbody */
  justify-content: space-between;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.roomDisplayer-room-content-wrapper tr:hover {
  border-color: #ff3703;
  transition: all 500ms ease;
}

.sameRoom-chipest-wrapper {
  background: #0258a4;
  color: white;
  border-radius: 5px;
  padding: 1px 4px;
  display: flex;
  gap: 2px;
}

.sameRoom-object-cancelation-sub-title-date {
  color: #25ac03;
}

.roomDisplayer-room-package-amenatis-content {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  color: black;
  justify-content: space-evenly;
  height: 100%;
  overflow-y: auto;
}

.roomDisplayer-room-package-amenatis-content-showall-btn {
  color: #1061a9;
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: -0.1px;
  cursor: pointer;
}