.payment-order-commition-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;

  .payment-commission-main-item {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }

  .payment-commission-drop-wrapper-wrapper {
    border: 1.5px solid #25ac03;
    padding: 2% 0;
    width: calc(100% - 3px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: white;
  }
  .payment-commission-drop-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    padding: 0 5%;
  }

  .payment-commission-drop-title-wrapper {
    display: flex;
    flex-direction: row-reverse;
    gap: 3px;
    height: fit-content;
  }

  .payment-commission-drop-title {
    min-width: 40%;
    font-size: 75%;
    font-weight: 600;
    color: #0258a4;
  }

  .payment-commission-drop-title-icon {
    color: #0258a4;
  }

  .payment-commission-drop-update-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
  }

  .payment-commission-drop-update-btn {
    align-self: center;
    color: white;
    cursor: pointer;
    background: #0258a4;
    border: none;
    border-radius: 3px;
    font-size: 85%;
    margin-top: 5%;
    padding: 3px 0;
    width: 50%;
  }

  .payment-commission-drop-update-btn:hover {
    background: #0258a4eb;
    transition: all 500ms ease;
  }

  .payment-commission-drop-update-input {
    border-radius: 3px;
    border: 1px solid #aaaaaa;
  }

  .payment-commission-drop-update-input::placeholder {
    color: black;
    font-weight: 600;
  }

  .commission-options {
    height: 20px;
  }
  .quick-add-commision-btns {
    padding: 0 5%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
