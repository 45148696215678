.credit-card-checkout-btn-wrapper {
  margin-top: 20px;
}

.credit-card-checkout-btn {
  width: 100%;
  box-shadow: -1px 8px 12px #00000052;
  border-radius: 8px;
  background: #0258a4;
  color: white;
  border: none;
  padding: 2% 0;
  cursor: pointer;
}

.credit-card-checkout-btn:hover {
  background-color: #1166b2;
  transition: all 500ms ease;
}

.credit-card-checkout-btn-loading {
  width: 100%;
  box-shadow: -1px 2px 5px #00000052;
  border-radius: 8px;
  padding: 1% 0 !important;
}

.credit-card-twoitem-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}

.credit-card-amount {
  text-align: center;
  width: 65%;
}

.credit-card-left-topay-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.credit-card-left-topay {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 80%;
  color: #25ac03;
  font-weight: 500;
}

.ci-ltp {
  display: flex;
  flex-direction: row;
  gap: 5%;
  font-weight: 600;
  font-size: 85%;
}

.ci-a-nop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ci-cn {
  display: flex;
  flex-direction: row;
}

.ci-nd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ci-nd-item-n {
  width: 80%;
}

.ci-cvvid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;
}

.ci-cvvid-item-cvv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.ci-cvvid-item-id {
  width: 60%;
}

.ci-submit {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.ci-pics {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin-top: 4%;
}

.ci-pics-title {
  font-size: 75%;
  color: #0258a4;
  font-weight: 600;
}

.ci-messeger {
  margin: 0 auto;
  margin-top: 5px;
}

.ci-a-nop-item-nop {
  background: #25ac03;
  color: white;
  display: flex;
  border-radius: 5px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: -moz-fit-content;
  width: fit-content;
}

.ci-a-nop-item-label {
  font-size: 80%;
  width: max-content;
  white-space: nowrap;
}

.payment-agent-dropdown {
  width: 24px;
  height: 15px;
  font-size: 90%;
  font-weight: 500;
  border-radius: 3px;
  outline: none;
  border: none;
  direction: rtl;
}

.ci-messeger-success {
  font-size: 80%;
  font-weight: 600;
  color: #25ac03;
  display: none;
}

.ci-messeger-error {
  font-size: 80%;
  font-weight: 600;
  color: #ff3703;
  display: none;
}

.ci-input-editing::placeholder {
  color: #0258a4 !important;
}

.additional-tash-fee {
  color: red;
}
