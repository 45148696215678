@import "../../assets/variables";

.packages-profile {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 5px 20px;
  background-color: #fcfdff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;

  .data-box {
    // width: calc(100% - 40px);
    //height: 200px;
    flex: 0 0 auto;
    box-shadow: 0 3px 10px 0 RGBA(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom: 20px;
    margin-top: 10px;

    .data-box-header {
      font-size: 1.5em;
      border-bottom: 1px solid RGBA(0, 0, 0, 0.2);
      margin-bottom: 10px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      background-color: $orange;
      padding: 7px 20px;
      justify-content: center;
      // color: white;
      font-weight: 700;
      color: white;
    }
    .data-box-header-agent {
      font-size: 1.5em;
      border-bottom: 1px solid RGBA(0, 0, 0, 0.2);
      margin-bottom: 10px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      background-color: $green;
      padding: 7px 20px;
      justify-content: center;
      // color: white;
      font-weight: 700;
      color: white;
    }

    .data-box-body {
      padding: 0 20px 15px 20px;
    }

    .header-icon {
      margin-left: 15px;
    }

    .order-footer {
      background-color: $steel_blue;
      width: calc(100% - 40px);
      flex: 0 0 auto;
      box-shadow: 0 3px 10px 0 RGBA(0, 0, 0, 0.2);
      background-color: white;
      padding: 20px;
    }
  }
}

@media (max-width: 768px) {
  .data-box-body-outer {
    justify-content: center;
    flex-direction: row-reverse;
  }
}
