.back-btn {
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
  color: #3a7eb5;
  border: 1.5px solid #3a7eb5;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  width: max-content;
}

.back-btn:hover {
  background-color: #3a7eb5;
  color: white;
  transition: all ease 500ms;
}
