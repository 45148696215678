.searchOption-wrapper {
  background-color: white;
  padding: 1% 3%;
  border-radius: 3px;
  cursor: pointer;
  transition: all ease 500ms;
  margin: auto 0;
  &.selected{
    background-color: #ff3703;
    color: white;
    transition: all ease 500ms;
  }
  &.disabled{
    background-color: grey;
    cursor: default;
    .searchOption-title {
      cursor: default;
    }
  }

  .searchOption-title {
    font-weight: 500;
    cursor: pointer;
  }
}

