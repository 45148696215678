.rooms-counter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: fixed;
  top: unset;
  z-index: 999;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 323px;
}

.rooms-counter-content {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.rooms-counter-content-left-fix {
  margin-left: -30%;
  margin-right: 0 !important;
}

.rooms-counter-item {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 10px #b5b3b3;
  background-color: white;
  border-radius: 5px;
  padding: 8% 15%;
  width: 100%;
  gap: 10px;
  /* display: none; */
}

.rooms-counter-item-active {
  display: block;
}

.rooms-counter-rooms-wrapper {
  display: flex;
  flex-direction: row !important;
  width: 70%;
  justify-content: space-between;
  padding-left: 15%;
  padding-right: 15%;
}

.rooms-counter-room-wrapper {
  display: flex;
  width: 100%;
}

.rooms-counter-adults-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rooms-counter-kids-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rooms-counter-inc-dec {
  display: flex;
  direction: rtl;
  height: fit-content;
}

.rooms-counter-text-wrapper {
  display: flex;
  flex-direction: column;
}

.rooms-counter-title {
  font-weight: 600;
}

.rooms-counter-sub-title {
  font-size: 70%;
  font-weight: 400;
}

.rooms-counter-button {
  background: none;
  border: none;
  cursor: pointer;
}

.rooms-counter-button:focus {
  outline: none;
}

.rooms-counter-button:hover {
  color: #007bff;
  transition: all 500ms ease;
}

.rooms-counter-inc-dec-value {
  color: #ff3703;
  font-weight: 600;
}

.rooms-counter-kids-info-wrapper {
  gap: 5px;
  display: flex;
  flex-direction: column;
  max-height: 20vh;
  overflow-y: auto;
  margin-top: 3%;
  &.birthDate {
    margin: 0 -30px;
  }
}

.rooms-counter-kid-wrapper {
  display: flex;
  justify-content: space-between;

  &.birthDate {
    margin-top: 5px;
  }
}

.rooms-counter-kid-number {
  color: #ff3703;
  white-space: nowrap;
}

.rooms-counter-kid-input {
  width: 30px;
  border-bottom: 1px solid #0258a4 !important;
  border: none;
}

.rooms-counter-kid-input:focus {
  outline: none;
}

.rooms-counter-rooms-page-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 5%;
  margin-top: 5%;
  border-top: 1px solid #b5b3b3;
  padding-top: 5%;
  justify-content: center;
}

.rooms-counter-rooms-page-item {
  cursor: pointer;
  background-color: #ffffff;
  color: #ff5427;
  border: 1px solid #ff6403;
  padding: 1% 3%;
  border-radius: 2px;
  font-weight: 500;
}

.rooms-counter-rooms-page-item:hover {
  background-color: #ff5427;
  color: white;
  border: 1px solid white;
  transition: all 500ms ease;
}

.rooms-counter-rooms-page-item-clicked {
  background-color: #ff5427;
  color: white;
  border: 1px solid white;
  transition: all 500ms ease;
  padding: 1% 3%;
  border-radius: 2px;
  font-weight: 500;
}

.rooms-counter-rooms-page-arrows {
  margin: auto 0;
  cursor: pointer;
}

.rooms-counter-rooms-page-arrows:hover {
  color: #007bff;
  font-size: 13.5px;
  transition: all 500ms ease;
}

.rooms-counter-kids-select {
  border: none;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.rooms-counter-kids-select:focus {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}
