.currency-icon {
  width: 20px;
  padding: 0 5px;
}

.currency-title {
  margin: 0;
  width: 60px;
}

.dropdown-menu-currency-item {
  font-size: 85%;
  font-weight: 600;
}

.arrow-icon-currency {
  padding: 0% 10%;
}
