.futureCommissions-wrapper {
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  gap: 4%;
}

.futureCommissions-title {
  font-weight: 500;
  color: #0258a4;
  font-size: 110%;
}

.footer-wrapper {
  width: 100%;
}

.futureCommissions-order-wrapper {
  height: 80%;
  width: 100%;
  overflow-y: auto;
  box-shadow: -1px 2px 7px #dcdcdc;
}

.futureCommissions-header-wrapper {
  background-color: #ff3703;
  border-radius: 5px;
  width: 100%;
  color: white;
  margin-bottom: 1%;
  display: table;
}

.futureCommissions-header-item {
  font-weight: 500;
  height: inherit;
  width: 16%;
}

.futureCommissions-orders-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  direction: ltr;
}

.futureCommissions-order {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  direction: rtl;
  border: 1px solid #d8d6d6;
  border-radius: 7.5px;
}

.futureCommissions-order-paid {
  background-color: #f0f9ed;
  border: none;
}

.futureCommissions-order-not-paid {
  background-color: #f3f3f3;
  border: none;
}

.futureCommissions-order-item {
  width: 16%;
}

.futureCommissions-order-item0 {
  color: #25ac03;
  font-weight: 700;
}

.futureCommissions-order-item1 {
  font-weight: 500;
}

.futureCommissions-order-item2 {
  color: #3a7eb5;
  font-size: 90%;
  font-weight: 500;
}

.futureCommissions-order-item3 {
  font-size: 90%;
}

.futureCommissions-order-item4 {
  font-weight: 700;
}

.futureCommissions-order-btn {
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
  color: #3a7eb5;
  border: 1.5px solid #3a7eb5;
  font-weight: 500;
  display: flex;
  width: max-content;
}

.futureCommissions-order-btn:hover {
  background-color: #3a7eb5;
  color: white;
  transition: all ease 500ms;
}

/* Scroll Bar */

.futureCommissions-wrapper ::-webkit-scrollbar {
  width: 8px;
}

.futureCommissions-wrapper ::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  width: fit-content;
  border-radius: 20px;
}

.futureCommissions-wrapper ::-webkit-scrollbar-thumb {
  background-color: #0258a4;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.futureCommissions-wrapper ::-webkit-scrollbar-thumb:hover {
  background-color: #0d71cb;
}

.futureCommissions-wrapper table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 5px;
  box-shadow: -1px 2px 7px #dcdcdc;
}

.futureCommissions-wrapper thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.futureCommissions-wrapper tbody tr {
  display: table;
  table-layout: fixed;
  font-size: 85%;
  min-height: 4rem;
}

.futureCommissions-wrapper tbody td {
  text-align: center;
}

.futureCommissions-order-displayer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #747474ad;
}

.futureCommissions-order-displayer {
  display: flex;
  flex-direction: column;
  padding: 0 5% 3%;
  background: white;
  margin: auto;
  height: 83%;
  border-radius: 7.5px;
  position: relative;
  z-index: 9999;
  align-items: center;
}

.futureCommissions-wrapper-icon-he {
  position: absolute;
  bottom: 2%;
  width: 83%;
  display: flex;
  flex-direction: row-reverse;
  left: 3%;
}

.futureCommissions-wrapper-icon {
  position: absolute;
  bottom: 2%;
  right: 2%;
  width: 85%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.futureCommissions-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 0;
  width: 100%;
}

.futureCommissions-content {
  flex: 1;
  width: fit-content;
}

.futureCommissions-paid-wrapper {
  padding: 5%;
}

.futureCommissions-paid-title {
  font-size: 110%;
  font-weight: 600;
  color: #ff4f22;
}
