.inputPass-component {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
  width: 100%;
}

.inputPass-title {
  font-size: 70%;
  font-weight: 500;
  color: #ff3703;
}

.inputPass-input-wrapper {
  padding: 2% 0;
  display: flex;
  flex-direction: row;
  gap: 1%;
  border: 1px solid #7070705c;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px #b5b3b3;
}

.inputPass-input {
  border: none;
  border-bottom: 1px solid #00000029;
  outline: none;
  margin: 0 5px;
  width: 100%;
}

.inputPass-input::placeholder {
  font-size: 90%;
}

.inputPass-img-wrapper {
  display: flex;
}

.inputPass-img {
  width: 15px;
}
