.rate{
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.agent-row{
    margin: 5px 0;
}

.approve-user{
    color: white;
    background-color: #25ac02;
    border: none;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
    width: 100px;
    height: 50px;
}