.card-paid-overlay{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 100%;

  background-color: rgba(green, 0.4);
  clip-path: polygon(0 0, 32% 0, 100% 100%, 68% 100%);
  border-radius: 10px;
  font-size: 180px;

  .text {
    position: absolute;
    transform: rotate(38deg);

    top: 15%;
    right: 22%;
  }
}