.calendar-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  position: fixed;
  right: 25%;
  height: fit-content;
  border-radius: 5px;
  width: max-content;
  cursor: auto;
  box-shadow: 0px 4px 8px #cbc8c8;
  z-index: 999;
}

.calendar {
  background-color: white;
  width: fit-content;
  border-bottom: 1px solid #b5b3b3;
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  gap: 10px;
  z-index: 1;
}

.calendar-bottom {
  background: white;
  border-radius: 0 0 5px 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .calendar-bottom-title {
    letter-spacing: 1px;
    margin: 2%;
    font-weight: 500;
    width: calc(100% - 30px);
    color: black;
  }
  .calendar-bottom-clear {
    width: 30px;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }
}

.calendar-days {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5.5px;
}

.calendar-days-wrapper {
  margin: 3% 0 0%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendar-divider {
  background-color: #b5b3b3;
  width: 0.5px;
}

.calendar-title {
  color: #ff5427;
  font-weight: 400;
  font-size: 105%;
}

.calendar-arrows-wrapper {
  display: flex;
}

.calendar-arrows {
  margin: auto 0;
  cursor: pointer;
  box-shadow: 0px 4px 8px #b7b4b4;
  padding: 2% 3%;
  border-radius: 50px;
  color: #757575;
}

.calendar-arrows:hover {
  color: #3a7eb5;
  font-size: 105%;
  transition: all 500ms ease;
}

.calendar-right {
  margin-right: auto;
}

.calendar-left {
  margin-left: auto;
}

.calendar-day {
  border-radius: 2px;
  font-size: 90%;
  font-weight: 600;
  padding: 6% 0;
  &.disabled {
    color: #d0d0d0;
    cursor: default;
    font-weight: 400;
  }
  &:not(.disabled):hover {
    color: #ff5427;
    font-weight: 600;
    //transition: all 500ms ease;
    cursor: pointer;
  }
}

.calendar-day-clicked {
  background-color: #eb5757;
  color: white;
}

.calendar-day-name {
  font-size: 85%;
  width: max-content;
  color: #5f5e5e;
}

.calendar-selected-between {
  background-color: #39a3f9;
  color: #fff;
  &:hover {
    color: #fff !important;
  }
}

.calendar-selected-arrival-departure {
  background-color: #eb5757;
  color: #fff;

  &:hover {
    color: #fff !important;
  }
}
