.notificationPass-component {
  border-radius: 10px;
  padding: 2% 4%;
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  gap: 5px;
}

.notificationPass-message {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.notificationPass-img {
  width: 45px;
}

.notificationPass-title-wrapper {
  display: flex;
  flex-direction: column;
  color: #4081ba;
}

.notificationPass-title {
  font-weight: 600;
  font-size: 110%;
}

.notificationPass-return-wrapper {
  display: flex;
  align-items: end;
}

.notificationPass-return {
  color: #ff3703;
  font-weight: 600;
  font-size: 70%;
  text-align: end;
  width: max-content;
  white-space: nowrap;
  margin-bottom: 1%;
  cursor: pointer;
}

.notificationPass-return:hover {
  text-decoration: underline;
  transition: all 500ms ease;
}
