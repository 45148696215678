.dataBox-wrapper {
  /* display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    padding: 4% 0%;
    gap: 5%;
    width: 100%;
    text-align: center; */
}

.dataBox-icon-wrapper {
  /* margin-top: -20%; */
}

.dataBox-icon {
  width: 25px;
}

.dataBox-title {
  font-weight: 500;
  margin-bottom: 4%;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.dataBox-value {
  font-weight: 700;
  font-size: 120%;
}

.dataBox-border {
  min-width: 200px;
  padding: 10px 30px;
  cursor: pointer;
}

.dataBox-border label {
  cursor: pointer;
}

.dataBox-border-blue {
  border: 1.5px solid #0258a4;
}

.dataBox-border-black {
  border: 1.5px solid #000000;
}

.dataBox-border-orange {
  border: 1.5px solid #ff3703;
}

.dataBox-border-green {
  border: 1.5px solid #25ac03;
}

.dataBox-text-blue {
  color: #0258a4;
}

.dataBox-text-black {
  color: #000000;
}

.dataBox-text-orange {
  color: #ff3703;
}

.dataBox-text-green {
  color: #25ac03;
}

@media (max-width: 768px) {
  .dataBox-border {
    min-width: unset;
    padding: 5px 10px;
    width: unset;
    margin-bottom: 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .dataBox-icon-wrapper {
    width: unset;
  }

  .dataBox-title {
    width: unset;
    margin-bottom: unset;
  }

  .dataBox-value {
    width: unset;
  }

  .dataBox-icon {
    width: 40px;
  }
}
