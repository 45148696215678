.payment-agent {
  // height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.payment-title-wrapper {
  background-color: #cfedc8;
  margin-top: 2vh;
  border-radius: 5px;
}

.payment-title {
  font-size: 125%;
  font-weight: 600;
  padding: 0.6% 2%;
  margin: 0;
}

.payment-wrapper-wrapper {
  height: 100%;
  margin-top: 2%;
  gap: 10px;
  // margin-bottom: 35vh;
}

.payment-order-summery {
  height: auto;
  /* border-radius: 7.5px !important; */
  display: flex;
  flex-direction: column;
  border-left: 1px solid #00000029;
  border-right: 1px solid #00000029;
  /*gap: 1.5em;*/
}

.payment-wrapper {
  height: 100%;
  border-radius: 7.5px !important;
}

.payment-payment-details-wrapper {
  display: flex;
  flex-direction: row;
}

.payment-card-details {
  // width: 100%;
  // margin: auto;
  // padding: 7% 0;
  background: white;
  border-radius: 10px;
  border: 1px solid #a7a7a7;
}

.splitPayment-card {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.payment-card-details-title {
  color: #0f61a9;
  font-weight: 700;
  font-size: 120%;
}

.payment-agent-input-wrapper {
  padding: 0.5% 0;
  display: flex;
  flex-direction: row;
  flex: 0 0 48%; /* Adjust the width as needed */
  margin-bottom: 10px;
  align-items: center;
  gap: 15px;
}

.payment-agent-label {
  font-weight: 500;
  width: 40%;
  font-size: 90%;
}

.payment-agent-label-fix {
  width: 80%;
}

.payment-agent-label-fix2 {
  width: 55%;
}

.payment-agent-label-fix3 {
  width: max-content;
}

.payment-agent-input {
  border: none;
  border-radius: 13px;
  padding: 0.6em 0%;
  box-shadow: inset 5px 4px 10px rgb(0 0 0 / 16%);
  background-color: white;
  width: 100%;
  font-size: 100%;
  font-weight: 500;
  height: fit-content;
  caret-color: transparent;
}

.payment-agent-input:not(:placeholder-shown) {
  direction: ltr; /* Change direction to ltr for entered text */
  padding: 0.6em 10px;
  box-sizing: border-box;
  border-bottom: 2px solid #dedede70;
}

.payment-agent-input-amount:not(:placeholder-shown) {
  direction: rtl !important; /* Change direction to ltr for entered text */
  padding: 0.6em 10px;
  box-sizing: border-box;
  border-bottom: 2px solid #dedede70;
}

.payment-agent-input::placeholder {
  color: black; /* Adjust placeholder text color as needed */
  border-bottom: 2px solid #dedede70;
  font-size: 90%;
  font-weight: 600;
  padding: 0 2.5%;
  width: 90%;
  display: flex;
  justify-content: center;
}

.payment-agent-input:focus {
  outline: none;
  /* outline-width: 1px; */
  /* outline-color: black; */
}

.payment-agent-dropdown {
  border: 2px solid #0258a4;
  border-radius: 5px;
  padding: 0.4em 2.5%;
  direction: ltr;
  background-color: #0258a408;
  letter-spacing: 0.1em;
  cursor: pointer;
  height: fit-content;
  width: 38%;
}

.payment-agent-dropdown-option {
  cursor: pointer !important;
}

.payment-agent-input-small {
  width: 70% !important;
  text-align: center;
}

.payment-agent-row-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.payment-split-payment {
  display: flex;
  flex-direction: column;
  /* width: 20%; */
  height: 100%;
  /* margin-top: 10%;
    margin-right: 1.5%; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.payment-split-payment:hover {
  background: #ff74511c;
}

.payment-agent-next-prev {
  font-size: 150%;
}

.payment-agent-add-card {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50px;
}

/* .payment-agent-add-card:hover {
    background-color: #ff9f86;
    transition: all ease 500ms;
} */

.payment-agent-added-cards-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 5px;
  height: 115px;
  overflow-y: auto;
}

.payment-agent-added-cards-wrap {
  position: relative;
}

.payment-agent-added-cards {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.payment-agent-number-circle {
  position: absolute;
  bottom: 5px;
  /* right: 2px; */
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #929292;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-agent-number {
  font-size: 80%;
  font-weight: 500;
  color: white;
}

.payment-except {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.payment-except-title {
  color: #0f61a9;
  font-weight: 700;
  font-size: 110%;
}

.payment-checkout-wrapper {
  display: flex;
  flex-direction: row;
}

.payment-checkout-title {
  color: #57c13c;
  font-weight: 700;
}

.payment-checkout-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;
  font-size: 100%;
  font-weight: 400;
  gap: 2%;
}

.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: green;
  font-weight: bold;
}

.payment-checkout-submit-button-wrapper {
  /* width: 35%;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 3% !important; */
  text-align: center;
  display: flex;
  margin: auto;
  margin-top: 3% !important;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.payment-checkout-submit-button {
  border: none;
  border-radius: 20px;
  padding: 5% 6px;
  background: #ff3703;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: 90%;
  white-space: nowrap;
}

.payment-checkout-submit-button:hover {
  color: #ff5427;
  background-color: white;
  border-color: #ff5427;
  border-width: 1px;
  border-style: solid;
  transition: all ease 500ms;
}

.payment-agent-icons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.payment-agent-icons {
  width: 25px;
  height: 18px;
}

.payment-order-content {
  flex: 1;
}

.payment-agent-price-payment-wrapper {
  background-color: #ff3703;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-weight: 700;
  font-size: 90%;
  padding: 2% 7%;
  margin-bottom: 10px;
}

.payment-order-checkinout {
  text-align: center;
  font-size: 80%;
  font-weight: 600;
  color: #0258a4;
}

.payment-checkout-submit-button-pay-later {
  border: none;
  border-radius: 10px;
  padding: 4% 3px;
  background: #0258b6;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 75%;
  white-space: nowrap;
}

.payment-checkout-submit-button-pay-later:hover {
  color: #0258b6;
  background-color: white;
  border-color: #0258b6;
  border-width: 1px;
  border-style: solid;
  transition: all ease 500ms;
}

.payment-underline {
  width: 90%;
  height: 1px;
  background-color: #c0c0c0;
  margin: 10px auto;
}

.payment-order-id {
  font-size: 90%;
  font-weight: 400;
  padding: 5%;
}

.payment-order-id-number {
  font-size: 95%;
  font-weight: 600;
}

/* Hotel Info Item */
.payment-hotelInfo-title {
  font-weight: 400;
  font-size: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.payment-hotelInfo-title-text {
  font-weight: 700;
  color: #0258a4;
}

.payment-hotelInfo-title-value {
  font-weight: 400;
  color: #0258a4;
}

.payment-hotelInfo-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: auto;
  justify-content: space-around;
  width: 100%;
}

.payment-hotelInfo-info-wrapper-item-item-value {
  width: max-content;
  white-space: nowrap;
  font-size: 83%;
  color: #1063ac;
  font-weight: 400;
  flex-direction: row;
  display: flex;
  margin: 0 15px;
}

.payment-hotelInfo-pricing-title {
  font-size: 70%;
  color: #232323;
  width: max-content;
  font-weight: 500;
}

.payment-hotelInfo-pricing-sub-title {
  color: #0258a4;
  font-weight: 600;
  text-align: start;
  font-size: 95%;
}

.payment-hotelInfo-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 5px;
  margin-top: 1%;
}

.payment-commission-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #25ac03;
  color: white;
  font-weight: 600;
  padding: 2%;
  align-items: center;
  font-size: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.payment-hotelInfo-wrapper {
  padding: 0 3%;
}

/* CSS Fixes for payment */

.cie-auto-height {
  height: auto !important;
}

.cie-visible-overflow {
  overflow: visible !important;
}

.cie-sticky-position {
  position: sticky !important;
  top: 0;
}

.cie-full-height {
  height: 100vh !important;
}

.cie-height-8vh {
  height: 8vh !important;
}

.cie-padding-7vh {
  // padding: 7vh !important;
}

/* New style right side */
.crumbs-header {
  background-color: white;
  border-radius: 2px;
  padding: 3% 0 3%;
  font-size: 100%;
}

.crumbs-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 75%;
  padding: 1.3% 3%;
  background: #25ac03;
  color: white;
}

.crumbs-details-title {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  text-align: center;
}

.crumbs-details-value {
  font-weight: 400;
  //font-size: 95%;
}

/* -- */

.ci-clientInfo-main-wrapper {
  display: flex;
  flex-direction: column;
}

.ci-clientInfo-title {
  font-size: 75%;
  font-weight: 800;
  /*padding: 0 5px;*/
  margin: 10px 5px;
}

.ci-clientInfo-wrapper {
  background-color: white;
  border-radius: 0px 0px 3px 3px;
  padding: 4% 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #00000029;
}

.ci-clientInfo-main-title {
  font-size: 82%;
  font-weight: 900;
  margin-bottom: 10px;
}

.ci-clientInfo-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.ci-clientInfo-info-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  width: 33%;
}

.ci-clientInfo-info-box-title-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.1em;
  justify-content: center;
  align-items: center;
}

.ci-clientInfo-info-box-title {
  font-size: 75%;
  font-weight: 500;
}

.ci-clientInfo-info-box-icon {
  font-size: 75%;
}

.ci-clientInfo-info-box-icon-smaller {
  font-size: 65%;
}

.ci-clientInfo-info-box-value {
  font-size: 75%;
  color: #457a98;
  word-break: break-all;
}

.ci-clientInfo-info-box-border {
  width: 0.5px;
  height: 80%;
  background-color: #d9d9d9;
  margin: auto 0;
}

/* -- */

.passangerList-wrapper {
  /* margin-top: 5%; */
  margin: 10px 0;
}

.passangerList-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.passangerList-list-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
}

.passangerList-list-item-title {
  font-size: 100%;
  font-weight: 600;
}

.passangerList-list-item-icon {
  font-size: 75%;
}

.passangerList-list-item-value {
  font-size: 80%;
  color: #429bc8;
}

.passangerList-list-item-title-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  align-items: baseline;
}

/* -- */

.ci-summery-wrapper {
  /* margin-top: 3%; */
  background-color: white;
  border-radius: 0px 0px 3px 3px;
}

.ci-summery-topay-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: center !important;
  padding: 6% 0;
}

.ci-summery-topay {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.ci-summery-border {
  width: 100%;
  height: 0.5px;
  background-color: #d9d9d9;
}

.ci-summery-content-wrapper {
  padding: 0 5%;
}

.ci-summery-fee1-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 4% 0;
}

.ci-summery-fee1-title {
  font-size: 80%;
  font-weight: 700;
}

.ci-summery-fee0-value {
  font-size: 100%;
  font-weight: 600;
  color: black;
  margin-top: auto;
}
.ci-summery-fee1-value {
  font-size: 100%;
  font-weight: 600;
  color: #25ac03;
  margin-top: auto;
}

.ci-summery-fee2-value {
  font-size: 100%;
  font-weight: 600;
  color: #ff3703;
  margin-top: auto;
}

.ci-summery-hotel-wrapper {
  /*padding: 6% 0;*/
  margin-bottom: 10px;
}

.ci-summery-hotel-title {
  font-size: 18px;
  font-weight: 700;
}

.ci-summery-hotel-info-wrapper {
  display: flex;
  flex-direction: row;
  /*gap: 5px;*/
  margin-top: 8px;
  align-items: baseline;
  font-size: 14px;

  .ci-summery-hotel-nights-title {
    font-size: 80%;
    font-weight: 600;
  }

  .ci-summery-hotel-nights-value {
    font-size: 75%;
    color: #467c97;
    margin: 0 4px;
  }

  .ci-summery-hotel-info-nights {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
}

.ci-summery-hotel-dates {
  font-size: 100%;
}

.ci-summery-hotel-more-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: baseline; */
  margin-top: 5px;
}

.ci-summery-hotel-room-name {
  font-size: 100%;
  font-weight: 500;
  flex: 1;
}

.ci-summery-hotel-room-name-multiple {
  color: #ff3703;
  font-weight: 600;
  font-size: 100%;
}

/* -- */

.hotelDisplay-wrapper {
  background-color: white;
}

.hotelDisplay-title-wrapper {
  padding: 2% 4%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
}

.hotelDisplay-title {
  font-size: 90%;
  font-weight: 600;
}

.hotelDisplay-title-icon {
  color: #25ac03;
}

.hotelDisplay-info-img {
  width: 100%;
  aspect-ratio: 2 / 1;
  object-fit: cover;
}

.hotelDisplay-info-wrapper {
  display: flex;
  flex-direction: column;
}

.hotelDisplay-info-content-wrapper {
  background-color: #f4e2dd;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 5%;
  padding: 2% 5%;
}

.hotelDisplay-info-content {
  display: flex;
  flex-direction: column;
  direction: ltr;
  text-align: left;
  gap: 0.2em;
}

.hotelDisplay-info-content-title {
  font-size: 80%;
  font-weight: 700;
}

.hotelDisplay-info-content-sub-title {
  font-size: 65%;
  color: #555454;
  font-weight: 300;
}

.hotelDisplay-info-content-expend-wrapper {
  align-items: center;
  justify-content: start;
  display: flex;
}

.hotelDisplay-info-content-expend-button {
  font-size: 145%;
  cursor: pointer;
}

.hotelDisplay-info-content-date-wrapper {
  font-size: 100%;
  margin-top: 0.1em;
}

.hotelDisplay-info-img-wrapper {
  // display: flex;
}

.hotelDisplay-expend-border-h {
  height: 0.5px;
  width: 100%;
  background-color: #d9d9d9;
}

.hotelDisplay-expend-border-v {
  margin: 0.9em 0;
  width: 0.5px;
  background-color: #d9d9d9;
}

.hotelDisplay-expend-basis-wrapper {
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hotelDisplay-expend-wrapper {
  padding: 0 5%;
}

.hotelDisplay-expend-room-wrapper {
  padding: 5% 0;
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: space-between;
}

.hotelDisplay-expend-room-img-wrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotelDisplay-expend-room-img {
  width: 80%;
  border-radius: 5px;
}

.hotelDisplay-expend-room-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hotelDisplay-expend-room-item {
  font-size: 75%;
  font-weight: 500;
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

.hotelDisplay-expend-room-item-icon {
  color: #0258a4;
  width: 1em;
}

.hotelDisplay-expend-pricing-wrapper {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
}

.hotelDisplay-expend-pricing-price {
  color: #0258a4;
  font-weight: 700;
}

.hotelDisplay-expend-pricing-text {
  font-size: 75%;
  display: flex;
  flex-direction: column;
}

.hotelDisplay-expend-pricing-sub-title {
  color: #ff3703;
}

.hotelDisplay-expend-check-wrapper {
  display: flex;
  flex-direction: row;
  padding: 5% 0;
  gap: 2%;
  justify-content: space-between;
}

.hotelDisplay-expend-check-item-1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 5px;
  align-items: center;
}

.hotelDisplay-expend-check-item-2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 3px;
  align-items: center;
}

.hotelDisplay-expend-check-item-3 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 3px;
  align-items: center;
}

.hotelDisplay-expend-check-item {
  padding: 0 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.hotelDisplay-expend-check-item-1-title {
  font-size: 80%;
  font-weight: 600;
  color: #0258a4;
}

.hotelDisplay-expend-check-item-2-title {
  font-size: 80%;
  font-weight: 600;
}

.hotelDisplay-expend-check-item-3-title {
  font-size: 70%;
  color: #0061cb;
}

.hotelDisplay-expend-check-item-icon {
  font-size: 70%;
}

.hotelDisplay-expend-basis {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 3px;
  align-items: center;
}

.hotelDisplay-expend-cancel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 3px;
  align-items: center;
}

.hotelDisplay-expend-important-info {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 3px;
  align-items: center;
}

.hotelDisplay-expend-basis-title {
  font-size: 80%;
  color: #25ac03;
}

.hotelDisplay-expend-cancel-title {
  font-size: 80%;
  color: #25ac03;
}

.hotelDisplay-expend-cancel-title-red {
  font-size: 80%;
  color: red;
}

.hotelDisplay-expend-important-info-title {
  font-size: 80%;
  text-decoration: underline;
  color: #0258b6;
  cursor: pointer;
}

.hotelDisplay-expend-basis-icon {
  font-size: 80%;
  color: #25ac03;
  font-weight: 600;
}

.hotelDisplay-expend-cancel-icon {
  font-size: 80%;
  color: #25ac03;
  font-weight: 600;
}

.hotelDisplay-expend-important-info-icon {
  font-size: 80%;
  color: #0258b6;
  font-weight: 600;
}

/* -- */
.moreToPay-wrapper {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 5%;
  gap: 10px;
}

.moreToPay-title {
  font-size: 100%;
  font-weight: 600;
  color: #ff3703;
}

.moreToPay-content {
  display: flex;
  flex-direction: column-reverse;
  gap: 3px;
}

.moreToPay-content-item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.moreToPay-content-item-value {
  color: #0258a4;
  font-size: 80%;
}

.moreToPay-content-item-title {
  font-size: 80%;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .splitPayment-card {
    padding: 10px;
    width: calc(100% - 20px);
  }
}
