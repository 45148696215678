.databox-container {
  gap: 20px;
}

.searchAgent-agent-search-page {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  margin: auto;
  height: 100%; */
}

.searchAgent-agent-info-wrapper {
  /* width: 55%; */
  /* display: flex; */
  /* flex-direction: row-reverse; */
  /* gap: 7.5%; */
  /* align-items: center; */
  /* margin: auto; */
}

.searchAgent-content-wrapper {
}

.searchAgent-search-bar-option-wrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.searchAgent-search-bar-wrapper {
  width: 100%;
  height: fit-content;
  border-radius: 5px;
}

.searchAgent-sr-upo-wrapper {
}

.searchAgent-up-coming-orders-wrapper {
  /* width: 80%;
  margin: auto;
  height: 100%; */
}

.searchAgent-messages-wrapper {
  background-color: white;
  width: 30%;
  height: 100%;
  box-shadow: 0px 2px 4px #cbc8c8;
}

.searchAgent-search-result-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .databox-container {
    gap: unset;
    width: 100%;
    flex-direction: column;
  }
}
