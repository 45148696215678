@import "../../../../assets/variables";


.flight-footer-row{

  margin-top: 10px;

  .luggage-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 120px;
    font-size: small;

    .luggage-row{
      gap: 5px;
      display: flex;
      align-items: center;
      .luggage-icon-warpper{
        &.include{
          color: $green
        }
        &:not(.include){
          color: red;
        }
      }
    }
  }

  .info-section{
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 10px;

    .info-title-bold{
      font-weight: 600;
    }

    .icon-warpper{
      font-size:medium;
      gap: 5px;
      display: flex;
    }

    .flight-icon{
      transform: rotate(270deg);
    }
  }
}

.status-remark-row{
  display: flex;
  padding: 10px 20px;
  font-size: small;
}