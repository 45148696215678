@import "../../../assets/variables";

.login-page {
  display: flex;
  margin: auto;
  height: 100%;
}

.overlay-login {
  background: white;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 7vh;
  z-index: 1;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  background: white;
  margin: auto;
  padding: 20px 30px;
  justify-content: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 12px 6px #0000005c;
}

.login-form-title {
  font-weight: 700;
  color: $blue;
  font-size: 18px;
  margin-bottom: 10px;
}

.login-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-input-padding-wrapper {
  padding-bottom: 10px;
}

.login-input-label {
  font-weight: 600;
  font-size: 90%;
  margin-bottom: 1%;
}

.login-input {
  border: none;
  outline: none;
  height: 30px;
  background: #e8e8e8;
  font-size: 100%;
  direction: ltr;
  width: calc(100% - 20px);
  padding: 2px 10px;
}

.login-info-wrapper {
  width: 100%;
}

.login-text {
  font-size: 90%;
  font-weight: 600;
}

.link-login {
  text-decoration: none;
  color: #0071c2;
}

.link-login:hover {
  color: #2ea8ff;
  transition: all ease 0.5s;
}

.login-submit-wrapper {
  width: 100%;
}

.login-submit-button {
  background: $blue;
  color: white;
  font-weight: 500;
  font-size: 100%;
  padding: 2.5% 0;
  border: none;
  width: 100%;
  cursor: pointer;
}

.login-submit-button:hover {
  background: #0f75d0;
  transition: all ease 0.5s;
}

.login-terms {
  font-weight: 600;
  font-size: 85%;
  text-align: center;
  margin-top: 10px;
}

.google-facebook-login {
  display: flex;
  margin-top: 3px;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.login-error {
  color: red;
  font-weight: 600;
  font-size: 85%;
  margin-bottom: 5%;
}

.login-password-input-container {
  position: relative;
}

.login-password-toggle-button {
  position: absolute;
  /* top: 50%; */
  right: 3%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  top: 55%;
  padding: 0;
}

@media (max-width: 1024px) {
  .login-form {
    padding: 10px;
    width: calc(95% - 20px);
  }
}
