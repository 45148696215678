.upper-nav-bar {
  background-color: #ffffff;
  width: 100%;
  position: relative;
  z-index: 10;
  /*height: 250px;*/
}

.upper-nav-bar-client-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100vw;
}

.logo-client-img-wrapper {
  display: flex;
  justify-content: end;
  width: 35%;
}

.upper-nav-bar-client {
  align-items: center;
  background-color: #fff;
  display: flex;
  gap: 1.8vw;
  height: 7vh;
  list-style-type: none;
  margin: 0;
  max-width: 80%;
}

.upper-nav-bar-client li {
  /* margin: auto; */
  font-size: 90%;
}

.navbar-item-client {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.navbar-item-client:hover {
  color: rgb(96, 95, 95);
  text-decoration: none;
}

.orange-button-client {
  background: #ff3703;
  color: white;
  border-color: #ff3703;
  border-style: solid;
  padding: 6px 20%;
  border-radius: 20px;
  cursor: pointer;
  width: max-content;
  font-weight: 500;
  margin-top: -3px;
  font-size: 95%;
}

.orange-button-client:hover {
  background: transparent;
  color: #ff3703;
  transition: all ease 0.5s;
}

.button-client {
  /* background: white; */
  /* color: black; */
  /* border-color: black; */
  background: #ff3703;
  color: white;
  border-color: #ff3703;
  border-style: solid;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  width: max-content;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 50px;
}

.button-client:hover {
  /* background: black;
    color: white; */
  background: transparent;
  color: #ff3703;
  transition: all ease 0.5s;
}

.sub-nav-client {
  background-color: #e8e8e8;
  height: 35%;
  font-size: 90%;
  padding: 0 20%;
  align-items: center;
  display: flex;
}

.upper-nav-bar-client-with-bottom-nav {
  padding: 0 1%;
}

/* .upper-nav-bar-with-bottom-nav {
  padding: 0 0 2%;
} */

.routeLocationDisplayer-wrapper {
  padding: 10px 20px;
  background-color: #e8e8e8;
  font-size: 90%;
  font-weight: 500;
  /* height: 30%; */
}

.routeLocationDisplayer-link {
  text-decoration: none;
  color: #ff3703;
}

.logo-img {
  height: 100%;
  max-height: 55px;
  object-fit: contain;
  margin: 0 5px;
}

@media (max-width: 1024px) {
  .logo-img {
    max-width: 20%;
  }

  .button-client {
    padding: 10px 10px;
    font-size: 1rem;
    margin-top: 20px;
  }
}
