.upload-logo {
  height: 23px;
  width: 27px;
}

.upload-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.upload-input-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.upload-input {
  border: transparent;
  width: calc(100% - 20px);
  outline: none;
  background-color: transparent;
}

.img-path {
  font-size: 14px;
  text-align: right;
  width: 100%;
  padding-right: 5px;
}

@media (max-width: 1024px) {
  .upload-input-wrapper {
    width: 100% !important;
  }
}
