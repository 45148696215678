.flights-filters-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-input-wrapper {
  margin-left: 20px;
}

.flights-filters-separator {
  height: 1px;
  background-color: #80808040;
  width: 100%;
  margin: 20px 0;
}

.flights-filters-clear {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin: 5px;
  cursor: pointer;
}
