.wallet-logo {
  width: 15%;
  padding: 2vh;
}

.balance-widget {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 6px #0000001c;
  border-radius: 10px;
}

.balance-widget.enabled {
  cursor: pointer;
}

.balance-text {
  font-size: 20px;
  padding: 10px 0;
}

.balance-number {
  font-size: 2vw;
  color: #25ac03;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .balance-number {
    font-size: 1rem;
  }
}
