@import "../../assets/variables";

$small-size: 20px;
$medium-size: 32px;
$large-size: 50px;

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;

  .loader-text {
    height: 20px;
    //width: 200px;
    //display: flex;
    //flex-direction: row;
    //align-items: center;
  }

  .loader {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    color: $orange;
    animation: fill 1s ease-in infinite alternate;

    &.small {
      width: $small-size;
      height: $small-size;
    }
    &.small::before {
      left: calc($small-size * 1.2);
    }
    &.small::after {
      right: calc($small-size * 1.2);
    }

    &.large {
      width: $large-size;
      height: $large-size;
    }
    &.large::before {
      left: calc($large-size * 1.3);
    }
    &.large::after {
      right: calc($large-size * 1.3);
    }
  }
  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    left: 48px;
    top: 0;
    color: $blue;
    animation: fill 0.9s ease-in infinite alternate;
  }

  .loader::after {
    left: auto;
    right: 48px;
    color: $green;
    animation-duration: 1.1s;
  }

  @keyframes fill {
    0% {
      box-shadow: 0 0 0 2px inset;
    }
    100% {
      box-shadow: 0 0 0 10px inset;
    }
  }
}
