.card {
  width: 70%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.card > .order-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 2rem);
  padding: 1rem;
}

.card > .order-status.cancelled {
  background-color: #f8d7da;
}

.card > .order-status.paid {
  background-color: #d4edda;
}

.card > .order-status.pending {
  background-color: #f9df8a;
}

.card > .image-and-map {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.card > .image-and-map > .image {
  width: 60%;
  height: 300px;
  object-fit: cover;
}

.card > .image-and-map > .map {
  width: 40%;
  height: 300px;
}

.card > .info-open-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: calc(100% - 40px);
  margin: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.card > .info-open-card > .info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.card > .info-open-card > .info > .icon-name-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card > .info-open-card > .info > .icon-name-date > .icon {
  width: 40px;
  aspect-ratio: 1;
}

.card > .info-open-card > .info > .cancelation > .item-wrapper {
  margin: unset;
  margin-right: 10px;
}

.card > .info-open-card > .open-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
}

.card > .info-open-card > .open-text > .icon {
  width: 20px;
  aspect-ratio: 1;
  margin-right: 10px;
}

.card > .info-open-card.open > .open-text > .icon {
  transform: rotate(180deg);
}

.card > .collapsed-wrapper {
  width: 100%;
}

@media (max-width: 1024px) {
  .card {
    width: 95%;
  }

  .card > .image-and-map {
    flex-direction: column;
  }

  .card > .image-and-map > .image {
    width: 100%;
    height: 200px;
  }

  .card > .image-and-map > .map {
    width: 100%;
    height: 200px;
  }

  .card > .info-open-card > .info > .icon-name-date > .icon {
    width: 30px;
  }

  .card > .info-open-card > .info > .cancelation > .item-wrapper {
    margin-right: 5px;
  }

  .card > .info-open-card > .open-text > .text {
    display: none;
  }

  .card > .info-open-card > .open-text > .icon {
    width: 15px;
  }

  .card > .collapsed-wrapper {
    flex-wrap: wrap;
  }
}
