.filters-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  border: 2px solid green;
  border-radius: 10px;
  height: 30px;
  margin: 2px 8px;
  padding: 5px;
  background-color: white;
  color: black;
}

.filters-tab-selected {
  background-color: green !important;
  color: white !important;
}

.filters-tab-disabled {
  /* color: white !important; */
  background-color: lightgray !important;
  border: 2px solid lightgray;
}

.filters-tab-image-wrapper {
  padding-left: 10px;
  height: 100%;
}

.filters-tab-image {
  height: 20px;
  font-size: 30px;
  padding: 4px;
  border: 1px solid green;
  border-radius: 4px;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.26);
}

.filters-tab-image-selected {
  background-color: white;
}

.filters-tab-text {
  width: calc(100% - 30px);
}