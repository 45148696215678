@import "../../assets/variables";

.register-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90%;
  margin: 20px;
  gap: 10px;
  max-width: 1700px;
  max-height: 800px;

  .register-inputs-container {
    height: calc(100% - 40px);
    background-color: white;
    width: 70%;
    border-radius: 20px;
    padding: 20px 30px;
    box-shadow: 0 0 10px #00000091;
    .register-inputs-header {
      text-align: center;
      margin-bottom: 10px;
      gap: 5px;
      .title {
        font-size: 18px;
        color: $steel_blue;
        font-weight: bold;
      }
      .sub-title {
        color: $endeavour;
        font-weight: bolder;
      }
      .sub-title2 {
        margin: 5px 0;
      }
    }

    .register-inputs-wrapper {
      height: calc(100% - 140px);
    }
  }

  .register-image-container {
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .client-pages.registeragent {
    height: unset !important;
    min-height: 100vh;
  }

  .client-pages.registeragent > .client-component {
    align-items: flex-start !important;
  }

  .register-container {
    height: unset;
    max-height: unset;
    margin: 10px;
  }
  .register-inputs-container {
    padding: 5px !important;
    width: calc(95% - 10px) !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .register-image-container {
    display: none;
  }

  .register-inputs-wrapper {
    height: unset !important;
  }
}
