.image-gallery {
  width: 100%;
  direction: ltr;
}

.carousel .control-dots {
  display: none;
}

.gallery-image {
  width: 100%;
  height: 100%;
}

.image-gallery .carousel li.slide {
  width: 100%;
  height: 40vh;
  /* cursor: zoom-in; */
}

.image-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* cursor: zoom-in; */
}

.image-gallery .row {
  gap: 1%;
  justify-content: space-between;
}

.image-gallery .col {
  /* justify-content: space-between; */
}

.gallery-8-wrapper-1 {
  width: 47%;
}

.gallery-8-wrapper-2 {
  width: 30%;
}

.gallery-8-wrapper-3 {
  width: 100%;
  height: 40vh;
}

.gallery-8-wrapper-1-item {
  height: 47.5%;
}

.gallary-img-no-images {
  width: 10vw !important;
  opacity: 0.8;
}

.gallery-img-1 {
  height: 25vh !important;
}

.gallery-img-2 {
  height: 14.5vh !important;
}

.gallery-img-3 {
  height: 7.1vh !important;
}

.gallery-img-4 {
  height: 7.1vh !important;
}

.gallery-img-5 {
  height: 13vh !important;
}

.gallery-img-6 {
  height: 13vh !important;
}

.gallery-img-7 {
  height: 13vh !important;
}

.gallery-img-8 {
  height: 40vh;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.9 !important;
}

.hotelProfile-component .carousel.carousel-slider .control-arrow:hover {
  opacity: 1;
}

@media (max-width: 1024px) {
  .gallery-8-wrapper-1.col {
    display: none;
  }

  .gallery-8-wrapper-2.col {
    display: none;
  }

  .gallery-8-wrapper-3 {
    width: 100%;
    height: 40vh;
  }
}
