.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table-header-row {
  position: sticky;
  top: 0;
  background-color: #0258a4 !important; /* Ensure background color is applied */
  color: white;
}

.blue-header-cell{
  text-align: center !important;
  border-right: 2px solid white;
  border-left: 2px solid white;
  color: white !important;
  font-weight: 700 !important;
  background-color: #0258a4 !important;
}

.passenger-name{
  
}