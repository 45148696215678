.star-rating {
  display: inline-block;
  color: #ff3703;
}

.star-icon {
  color: #ff3703;
}

.star-icon.empty {
  color: lightgray;
}
